import { ActionIcon, Button, Input } from '@mantine/core';
import '@mantine/core/styles/Button.css';
import { useDebouncedState } from '@mantine/hooks';
import { CellValueChangedEvent, ColDef, SideBarDef } from 'ag-grid-enterprise';
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import {
  CreatePlanningProjectRequest,
  PlanningPriority,
  PlanningProjectResponse,
  UpdatePlanningProjectRequest,
} from 'common/api/multimap';
import { asDateTime } from 'common/helpers/dateHelpers';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { AG_GRID_LOCALE_NOR } from 'locales/agGridLocale';
import { useMemo, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { useProjectContext } from '../components/ProjectContext';
import useAddPlanningProject from '../hooks/useAddPlanningProject';
import useGetPlanningProjects from '../hooks/useGetPlanningProjects';
import useRemovePlanningProject from '../hooks/useRemovePlanningProject';
import useUpdatePlanningProject from '../hooks/useUpdatePlanningProject';

interface Workspace {
  systemName: string;
  workspaceId: string;
}

export const PlanningProjectOverview = () => {
  const [workspace] = useLocalStorage<Workspace | undefined>('workspace', undefined);
  const [quickFilterText, setQuickFilterText] = useDebouncedState('', 400);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [projectToDelete, setProjectToDelete] = useState<string | null>('');
  const { setSelectedProjectIdToFilterOn } = useProjectContext();
  const gridRef = useRef<any>(null);

  const navigate = useNavigate();

  const { t } = useTranslation('planning');

  const [projectsIsLoading, projectsIsError, allProjects] = useGetPlanningProjects(workspace?.workspaceId ?? '');
  const [, , , isPlanningProjectAdded, addPlanningProject] = useAddPlanningProject();
  const [, , , isPlanningProjectUpdated, updatePlanningProject] = useUpdatePlanningProject();
  const [, , , isPlanningProjectRemoved, removeProject] = useRemovePlanningProject();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFilterText(e.currentTarget.value);
  };

  const sideBar = useMemo(() => {
    const sb: SideBarDef = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
            // Other parameters...
          },
        },
      ],
      position: 'left',
    };
    return sb;
  }, []);

  const defaultColDef = {
    filter: 'agTextColumnFilter',
    suppressHeaderMenuButton: true,
  };

  const formatNumber = (value: number | null) => {
    if (value == null) return t('noValue');
    return new Intl.NumberFormat('nb-NO').format(value);
  };

  const onProjectCellValueChanged = (event: CellValueChangedEvent) => {
    const request: UpdatePlanningProjectRequest[] = [
      {
        planningProjectId: event.data.planningProjectId,
        systemName: event.data.systemName,
        systemDescription: event.data.systemDescription,
        planningProjectNumber: event.data.planningProjectNumber,
        priorityId: event.data.priorityId,
        consequenceDegree: event.data.consequenceDegree,
      },
    ];
    updatePlanningProject({ request: request });
  };

  const renderTranslatedValue = (params: CustomCellRendererProps) => {
    return (t(params.value) || params.value) as string;
  };

  const addProject = () => {
    const request: CreatePlanningProjectRequest = {
      systemName: '',
      systemDescription: '',
      planningProjectNumber: '',
      priorityId: PlanningPriority.Low,
      workspaceId: workspace?.workspaceId ?? '',
      consequenceDegree: 0,
    };
    addPlanningProject({ request: request });
  };

  const handleDeleteProject = (projectId: string) => {
    removeProject({ id: projectId });
  };

  const handleNavigationToMeasureOverviewClicks = (projectId: string | null) => {
    setSelectedProjectIdToFilterOn(projectId); // Set the selected project ID
    navigate('/planning'); // Redirect to /planning
  };

  const columns: ColDef<PlanningProjectResponse>[] = [
    {
      headerName: t('projectOverview.planningProjectNumber'),
      field: 'planningProjectNumber',
      hide: true,
    },
    {
      headerName: t('projectOverview.systemName'),
      field: 'systemName',
      lockPosition: true,
      editable: true,
      cellRenderer: (params: CustomCellRendererProps) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {params.value && (
              <Button
                radius={'4px'}
                w={'2em'}
                ml={'-.25em'}
                mr={'.5em'}
                px={0}
                size="compact-xs"
                variant="subtle"
                color="black"
                onClick={() => {
                  handleNavigationToMeasureOverviewClicks(params.data.planningProjectId);
                }}
              >
                <span className={'ag-icon ag-icon-filter'}></span>
              </Button>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {params.value === '' ? (
                <span style={{ color: 'grey' }}>{t('projectOverview.planningProjectNamePlaceholder')}</span>
              ) : (
                <span>{params.value}</span>
              )}
            </div>
          </div>
        );
      },
      cellClass: 'clickable',
      pinned: 'left',
    },
    {
      headerName: t('projectOverview.systemDescription'),
      field: 'systemDescription',
      sortable: false,
      editable: true,
      flex: 1,
      cellRenderer: (params: CustomCellRendererProps) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {params.value === '' ? (
              <span style={{ color: 'grey' }}>{t('projectOverview.planningProjectDescriptionPlaceholder')}</span>
            ) : (
              <span>{params.value}</span>
            )}
          </div>
        );
      },
    },
    {
      headerName: t('priorityId'),
      field: 'priorityId',
      filter: true,
      editable: true,
      width: 130,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: [PlanningPriority.High, PlanningPriority.Medium, PlanningPriority.Low],
        cellRenderer: renderTranslatedValue,
      },
      filterParams: {
        values: [PlanningPriority.High, PlanningPriority.Medium, PlanningPriority.Low],
        cellRenderer: renderTranslatedValue,
      },
      cellRenderer: renderTranslatedValue,
    },
    {
      headerName: t('consequenceDegree'),
      field: 'consequenceDegree',
      editable: true,
      filter: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [0, 1, 2, 3],
      },
    },
    {
      headerName: t('projectOverview.measureAmount'),
      field: 'measureAmount',
      filter: false,
      width: 140,
    },
    {
      headerName: t('projectOverview.objectAmount'),
      field: 'objectAmount',
      filter: false,
      width: 130,
    },
    {
      headerName: t('totalEstimateCost'),
      filter: 'agNumberColumnFilter',
      field: 'totalEstimateCost',
      width: 185,
      cellRenderer: (params: CustomCellRendererProps) => formatNumber(params.value),
    },
    {
      headerName: t('totalCalculatedCost'),
      filter: 'agNumberColumnFilter',
      field: 'totalCalculatedCost',
      width: 185,
      cellRenderer: (params: CustomCellRendererProps) => formatNumber(params.value),
    },
    {
      headerName: t('createdOn'),
      field: 'createdOn',
      cellRenderer: (params: any) => {
        return asDateTime(params.value);
      },
      width: 130,
      sort: 'desc',
      sortingOrder: ['desc', 'asc'],
    },
    {
      headerName: t('modifiedOn'),
      field: 'modifiedOn',
      cellRenderer: (params: any) => {
        return asDateTime(params.value);
      },
      width: 140,
    },
    {
      headerName: t('buttons.delete'),
      minWidth: 75,
      maxWidth: 75,
      cellClass: 'd-flex',
      cellRenderer: (params: CustomCellRendererProps) => (
        <ActionIcon
          variant="subtle"
          color="grey"
          aria-label="delete"
          size={'sm'}
          onClick={() => {
            setProjectToDelete(params.data.planningProjectId);
            setShowDeleteModal(true);
          }}
        >
          <MdDelete />
        </ActionIcon>
      ),
      editable: false,
      sortable: false,
      filter: false,
    },
  ];

  return (
    <div>
      {projectsIsError ? (
        <div>Noe gikk galt</div>
      ) : (
        allProjects != null && (
          <>
            <div className="d-flex mb-2">
              <Button className="me-2" size="xs" onClick={() => addProject()}>
                {t('buttons.addProject')}
              </Button>
              <Button className="me-2" size="xs" onClick={() => handleNavigationToMeasureOverviewClicks(null)}>
                {t('buttons.measureOverview')}
              </Button>
              <Input size="sm" placeholder={t('search')} style={{ marginTop: '-1px' }} onChange={handleChange} />
            </div>
            <div className="w-100" style={{ fontWeight: 400, height: '800px', overflow: 'auto' }}>
              <AgGridReact
                getRowId={(p) => p.data.planningProjectId}
                ref={gridRef}
                rowData={allProjects}
                columnDefs={columns}
                defaultColDef={defaultColDef}
                rowHeight={35}
                onCellValueChanged={onProjectCellValueChanged}
                masterDetail={true}
                detailRowHeight={210}
                pagination={true}
                paginationPageSize={50}
                suppressColumnVirtualisation={true}
                suppressRowVirtualisation={true}
                localeText={AG_GRID_LOCALE_NOR}
                sideBar={sideBar}
                quickFilterText={quickFilterText}
                gridOptions={{
                  alwaysShowVerticalScroll: true,
                }}
              />
            </div>
          </>
        )
      )}
      {showDeleteModal && (
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered={true} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              Slett prosjekt {allProjects?.find((project) => project.planningProjectId === projectToDelete)?.systemName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {' '}
              Sletting av dette prosjektet vil gjøre{' '}
              {allProjects?.find((project) => project.planningProjectId === projectToDelete)?.measureAmount} tiltak
              prosjektløse. Er du sikker på at du vil slette dette prosjektet? <br></br>Merk: Man kan ikke angre etter
              man har slettet et prosjekt.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="tertiary"
              onClick={() => {
                setShowDeleteModal(false);
              }}
            >
              Avbryt
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (projectToDelete != null) {
                  handleDeleteProject(projectToDelete);
                }
                setShowDeleteModal(false);
              }}
            >
              Slett prosjekt
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
