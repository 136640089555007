import { AxiosError } from 'axios';
import { planningApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import {
  PlanningMeasureResponse,
  PlanningProjectResponse,
  UpdatePlanningMeasureRequest,
  ValidationProblemDetails,
} from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type UpdateParams = {
  request: UpdatePlanningMeasureRequest[];
};

function useUpdatePlanningMeasure(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  PlanningMeasureResponse[] | undefined,
  (params: UpdateParams) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    ({ request }: UpdateParams) => {
      return planningApi.planningPlanningMeasurePut(request).then((x) => x.data);
    },
    {
      onMutate: async (params: UpdateParams) => {
        await queryClient.cancelQueries(QueryKeys.PlanningMeasures);

        const previousData = queryClient.getQueryData<PlanningMeasureResponse[] | undefined>(
          QueryKeys.PlanningMeasures,
        );

        const projects = queryClient.getQueryData<PlanningProjectResponse[] | undefined>(QueryKeys.PlanningProjects);

        queryClient.setQueryData<PlanningMeasureResponse[] | undefined>(QueryKeys.PlanningMeasures, (oldData) => {
          if (!oldData) return undefined;
          return oldData.map((pm) => {
            const test = params.request.find((r) => r.planningMeasureId === pm.planningMeasureId);

            let projectName = '';
            if (pm.planningProjectId != null) {
              projectName = projects?.find((x) => x.planningProjectId === pm.planningProjectId)?.systemName ?? '';
            }

            if (test) {
              pm.consequenceDegree = test.consequenceDegree!;
              pm.planningPriorityId = test.planningPriorityId!;
              pm.systemName = test.systemName!;
              pm.systemDescription = test.systemDescription!;
              pm.planningProjectName = projectName;
            }

            return pm;
          });
        });

        // Return a context to rollback the optimistic update if the mutation fails
        return { previousData };
      },
      onError: (err, params, context) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }

        queryClient.setQueryData(QueryKeys.PlanningMeasures, context?.previousData);
      },
      onSettled: () => {
        // Refetch the data to ensure it’s up-to-date with the server
        queryClient.invalidateQueries(QueryKeys.PlanningMeasures);
      },
    },
  );

  const update = (params: UpdateParams) => mutate(params);

  return [isLoading, isError, validationError, data, update];
}

export default useUpdatePlanningMeasure;
