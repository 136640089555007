import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

export const DeactiveOrganization = () => {
  const { profileInfo } = useAuthenticatedUser();

  const navigate = useNavigate();

  const { t } = useTranslation('translation', { keyPrefix: 'features.user.deactivatedOrganization' });

  useEffect(() => {
    if (
      profileInfo &&
      (profileInfo?.isSystemAdministrator || profileInfo?.organization == null || profileInfo?.organization.isActive)
    ) {
      navigate('/');
    }
  }, [navigate, profileInfo]);

  return (
    <>
      <p className="fs-3">{t('deactivatedText')}</p>
      <p className="fs-5">
        <Trans
          i18nKey="features.user.deactivatedOrganization.contactAdmin"
          components={{
            mail: (
              <a href="mailto:multiMap@multiconsult.no" className="text-warning">
                'link'
              </a>
            ),
          }}
        ></Trans>
      </p>
    </>
  );
};
