// eslint-disable-next-line import/named
import { AuthError, AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { IResetPasswordTokenClaims } from 'auth/IResetPasswordTokenClaims';
import { b2cPolicies, createResetPasswordRequest } from 'auth/authConfig';
import { AccessControl, UserInfoResponse } from 'common/api/multimap';
import useLocalization from 'common/hooks/useLocalization';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import useGetUserInfo from './hooks/useUserInfo';

interface IAuthenticatedUserContext {
  isPasswordResetFlow: boolean;
  acl: AccessControl[];
  hasAccess: (access?: AccessControl) => boolean;
  profileInfo: UserInfoResponse | undefined;
}

export const AuthenticatedUserContext = React.createContext<IAuthenticatedUserContext | undefined>(undefined);

export const useAuthenticatedUser = () => {
  const context = React.useContext(AuthenticatedUserContext);
  if (context === undefined) {
    throw new Error('useAuthenticatedUser must be used within a AuthenticatedUserProvider');
  }
  return context;
};

export const AuthenticatedUserProvider: React.FC<{ children?: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const [isPasswordResetFlow, setIsPasswordResetFlow] = useState<boolean>(false);
  const { instance: msalInstance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // do not fetch profile if we're handling password reset
  const [isLoading, , profileInfo, fetchProfileInfo] = useGetUserInfo(isAuthenticated && !isPasswordResetFlow);
  const [currentLanguage, setLanguage] = useLocalization();

  const navigate = useNavigate();

  useEffect(() => {
    // always use user's preferred language if available
    if (profileInfo?.preferredLanguage && profileInfo?.preferredLanguage !== currentLanguage.id) {
      setLanguage(profileInfo?.preferredLanguage);
    }

    // redirect user to inactive organization page, if user is not admin and user's organization is inactive/disabeld
    if (
      !profileInfo?.isSystemAdministrator &&
      profileInfo?.organization != null &&
      !profileInfo?.organization.isActive
    ) {
      navigate('/deactive-organization', { replace: true });
    }
  }, [currentLanguage.id, navigate, profileInfo, setLanguage]);

  useEffect(() => {
    const resetPasswordRequest = createResetPasswordRequest();
    const callbackId = msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        const error = event.error as AuthError;
        if (error?.errorMessage?.indexOf('AADB2C90118') > -1) {
          msalInstance.loginRedirect(resetPasswordRequest);
        }
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        const authResult = event.payload as AuthenticationResult;
        if (authResult.fromCache && profileInfo) return;
        // refresh ACL by fetching profile info on every access token refresh
        fetchProfileInfo();
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        const payload = event.payload as AuthenticationResult;
        const claims = payload?.idTokenClaims as IResetPasswordTokenClaims;
        if (claims && claims.acr === b2cPolicies.names.forgotPassword.toLowerCase()) {
          setIsPasswordResetFlow(true);
          setTimeout(() => {
            return msalInstance.logout();
          }, 3000);
        }
      }
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, [msalInstance, fetchProfileInfo, profileInfo]);

  const acl = profileInfo?.acl ?? [];
  const hasAccess = (access: AccessControl | undefined) => {
    if (!isAuthenticated) return false;
    if (!access) return false;

    return acl.includes(access);
  };

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex justify-content-center align-items-center">
        <LoadingSpinner isLoading={isLoading} />
      </div>
    );
  }

  return (
    <AuthenticatedUserContext.Provider value={{ isPasswordResetFlow, acl, hasAccess, profileInfo }}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};
