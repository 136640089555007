import { AxiosError } from 'axios';
import { planningApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import {
  CreatePlanningProjectRequest,
  PlanningProjectResponse,
  ValidationProblemDetails,
} from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type CreateParams = {
  request: CreatePlanningProjectRequest;
};

function useAddPlanningProject(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  PlanningProjectResponse | undefined,
  (params: CreateParams) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    async ({ request }: CreateParams) => {
      return planningApi.planningPlanningProjectPost(request).then((x) => x.data);
    },
    {
      onMutate: async (params: CreateParams) => {
        await queryClient.cancelQueries(QueryKeys.PlanningProjects);

        const previousData = queryClient.getQueryData<PlanningProjectResponse[] | undefined>(
          QueryKeys.PlanningProjects,
        );

        queryClient.setQueryData<PlanningProjectResponse[] | undefined>(QueryKeys.PlanningProjects, (oldData) => {
          if (!oldData) return undefined;

          const newData = [...oldData];
          const thisProject: PlanningProjectResponse = {
            planningProjectNumber: '',
            planningProjectId: crypto.randomUUID(),
            systemName: params.request.systemName || '',
            systemDescription: params.request.systemDescription || '',
            priorityId: 'Low',
            consequenceDegree: 0,
            measureAmount: 0,
            objectAmount: 0,
            totalEstimateCost: 0,
            totalCalculatedCost: 0,
            createdOn: new Date().toISOString(),
            planningMeasures: [],
            totalYearCosts: [],
          };

          newData.push(thisProject);
          return newData;
        });

        return { previousData };
      },
      onError: (err, params, context) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }

        // Rollback the optimistic update if there is an error
        queryClient.setQueryData(QueryKeys.PlanningProjects, context?.previousData);
      },
      onSettled: () => {
        queryClient.invalidateQueries(QueryKeys.PlanningProjects);
      },
    },
  );

  const add = (params: CreateParams) => mutate(params);

  return [isLoading, isError, validationError, data, add];
}
export default useAddPlanningProject;
