import { AxiosError } from 'axios';
import { planningApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import {
  PlanningProjectResponse,
  UpdatePlanningProjectRequest,
  ValidationProblemDetails,
} from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type UpdateParams = {
  request: UpdatePlanningProjectRequest[];
};

function useUpdatePlanningProject(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  PlanningProjectResponse[] | undefined,
  (params: UpdateParams) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    async ({ request }: UpdateParams) => {
      // Update project API call
      return planningApi.planningPlanningProjectPut(request).then((response) => response.data);
    },
    {
      onMutate: async (params: UpdateParams) => {
        await queryClient.cancelQueries(QueryKeys.PlanningProjects);

        const previousData = queryClient.getQueryData<PlanningProjectResponse[]>(QueryKeys.PlanningProjects);

        queryClient.setQueryData<PlanningProjectResponse[] | undefined>(QueryKeys.PlanningMeasures, (oldData) => {
          if (!oldData) return undefined;
          return oldData.map((pm) => {
            const test = params.request.find((r) => r.planningProjectId === pm.planningProjectId);

            if (test) {
              pm.planningProjectId = test.planningProjectId!;
              pm.systemName = test.systemName!;
              pm.systemDescription = test.systemDescription!;
              pm.planningProjectNumber = test.planningProjectNumber!;
              pm.priorityId = test.priorityId!;
              pm.consequenceDegree = test.consequenceDegree!;
            }
            return pm;
          });
        });

        // Return previous data in case we need to roll back
        return { previousData };
      },
      onError: (err, params, context) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }

        // Roll back to previous data in case of error
        queryClient.setQueryData(QueryKeys.PlanningProjects, context?.previousData);
      },
      onSettled: () => {
        // Refetch the data to ensure it's up-to-date with the server
        queryClient.invalidateQueries(QueryKeys.PlanningProjects);
      },
    },
  );

  const update = (params: UpdateParams) => mutate(params);

  return [isLoading, isError, validationError, data, update];
}

export default useUpdatePlanningProject;
