import { Button, Flex, Input } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { PlanningMeasureMainCategory, PlanningPriority, UpdatePlanningMeasureRequest } from 'common/api/multimap';
import { ConditionDegreeIcon } from 'common/components/ConditionDegreeIcon/ConditionDegreeIcon';
import { arrayRange } from 'common/helpers/array.helpers';
import { Guid } from 'common/types/guid.type';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetObjectTypeDetail from 'features/admin/object-types/hooks/useGetObjectTypeDetail';
import useGetWorkspaceDetail from 'features/admin/workspaces/hooks/useGetWorkspaceDetail';
import { useEffect, useState } from 'react';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import useGetPlanningAvailableParameters from '../hooks/useGetPlanningAvailableParameters';
import useGetSinglePlanningMeasure from '../hooks/useGetSinglePlanningMeasure';
import useUpdatePlanningMeasure from '../hooks/useUpdatePlanningMeasure';

export interface IProps {
  show: boolean;
  onHide: () => void;
  workspaceId: string;
  planningMeasureId: string;
}

export const UpdatePlanningMeasureModal: React.FC<IProps> = ({ show, onHide, workspaceId, planningMeasureId }) => {
  const emptyGuid = '00000000-0000-0000-0000-000000000000';

  const [planningMeasureIsLoading, planningMeasureIsError, planningMeasure, fetchSinglePlanningMeasure] =
    useGetSinglePlanningMeasure(planningMeasureId ?? '');

  const [selectedParentId, setSelectedParentId] = useState<string>('');
  const [selectedMainId, setSelectedMainId] = useState<string>('');
  const [selectedChildId, setSelectedChildId] = useState<string>('');
  const [selectedIdForAvailableParameters, setSelectedIdForAvailableParameters] = useState<Guid>(
    planningMeasure?.mainObjectId || emptyGuid,
  );
  const [selectedParameterIds, setSelectedParameterIds] = useState<string[]>([]);
  const [isPlanningMeasureClosed, setIsPlanningMeasureClosed] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [initialValues, setInitialValues] = useState<UpdatePlanningMeasureRequest | null>(null);

  const { t } = useTranslation('planning');

  const [isLoading, isError, workspaceDetail] = useGetWorkspaceDetail(workspaceId ?? '');
  const [, , , isPlanningMeasureUpdated, updateMeasure] = useUpdatePlanningMeasure();
  const [availableParametersIsLoading, availableParametersIsError, availableParameters, fetch] =
    useGetPlanningAvailableParameters(selectedIdForAvailableParameters);
  const [typeIsLoading, , type] = useGetObjectTypeDetail(
    workspaceDetail ? workspaceDetail.objectType.objectTypeId : '',
  );

  useEffect(() => {
    let parentId = null;
    let mainId = null;
    let childId = null;

    const initialObject = workspaceDetail?.levels?.find((level) => level.objectId === planningMeasure?.mainObjectId);

    switch (initialObject?.level) {
      case 2:
        parentId = initialObject.objectId;
        break;
      case 3:
        mainId = initialObject.objectId;
        parentId = initialObject.parentId;
        break;
      case 4:
        childId = initialObject.objectId;
        mainId = workspaceDetail?.levels?.find((level) => level.objectId === initialObject.parentId)?.objectId;
        parentId = workspaceDetail?.levels?.find((level) => level.objectId === initialObject.parentId)?.parentId;
        break;
      default:
        // Optional: Handle cases where initialObject is undefined or level doesn't match 2, 3, or 4
        break;
    }
    setSelectedParentId(parentId || '');
    setSelectedMainId(mainId || '');
    setSelectedChildId(childId || '');
    setSelectedIdForAvailableParameters(planningMeasure?.mainObjectId || emptyGuid);
    setSelectedParameterIds(planningMeasure?.planningMeasureParameters?.map((x) => x.mappingParameterId) || []);
    setIsPlanningMeasureClosed(planningMeasure?.planningMeasureDone || false);
  }, [planningMeasure]);

  const parentObjectOptions = workspaceDetail?.levels?.filter((level) => level.level === 2);
  let mainObjectOptions = workspaceDetail?.levels?.filter((level) => level.level === 3);
  let childObjectOptions = workspaceDetail?.levels?.filter((level) => level.level === 4);

  if (planningMeasure?.mainObjectName != null) {
    mainObjectOptions = mainObjectOptions?.filter((opt) => opt.parentId === selectedParentId);
  }

  if (planningMeasure?.childObjectName != null) {
    childObjectOptions = childObjectOptions?.filter((opt) => opt.parentId === selectedMainId);
  }

  const convertedParentObjectOptions = [
    ...(parentObjectOptions?.map((x) => ({
      value: x.objectId,
      label: x.systemName,
    })) || []),
  ];

  const convertedMainObjectOptions = [
    ...(mainObjectOptions?.map((x) => ({
      value: x.objectId,
      label: x.systemName,
    })) || []),
  ];

  const convertedChildObjectOptions = [
    ...(childObjectOptions?.map((x) => ({
      value: x.objectId,
      label: x.systemName,
    })) || []),
  ];

  useEffect(() => {
    fetch();
  }, [selectedIdForAvailableParameters]);

  useEffect(() => {
    if (isPlanningMeasureUpdated) {
      onHide();
    }
  }, [isPlanningMeasureUpdated]);

  const handleCardSelection = (cardId: string) => {
    if (selectedParameterIds.includes(cardId)) {
      setSelectedParameterIds(selectedParameterIds.filter((id) => id !== cardId));
    } else {
      setSelectedParameterIds([...selectedParameterIds, cardId]);
    }
  };

  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<UpdatePlanningMeasureRequest>();

  useEffect(() => {
    if (planningMeasure) {
      const initialFormData = {
        planningMeasureId: planningMeasure.planningMeasureId,
        systemName: planningMeasure.systemName,
        systemDescription: planningMeasure.systemDescription,
        planningMeasureNumber: planningMeasure.planningMeasureNumber,
        planningPriorityId: planningMeasure.planningPriorityId,
        planningMeasureMainCategoryId: planningMeasure.planningMeasureMainCategoryId,
        planningMeasureDone: planningMeasure.planningMeasureDone,
        planningMeasureFinishedCost: planningMeasure.planningMeasureFinishedCost,
        finishedYear: planningMeasure.finishedYear,
        consequenceDegree: planningMeasure.consequenceDegree,
        mainObjectId: planningMeasure.mainObjectId,
      };
      setInitialValues(initialFormData);
      reset(initialFormData);
    }
  }, [planningMeasure, show]);

  const [quickFilterText, setQuickFilterText] = useDebouncedState('', 400);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFilterText(e.currentTarget.value);
  };

  const filteredParameters = Array.isArray(availableParameters)
    ? availableParameters.filter((param: { mappingParameterName: string }) =>
        param.mappingParameterName.toLowerCase().includes(quickFilterText.toLowerCase()),
      )
    : [];

  const updatePlanningMeasure = (d: UpdatePlanningMeasureRequest) => {
    const selectedParameterIdsToUpdate = selectedParameterIds.filter((id) =>
      availableParameters?.some((x) => x.mappingParameterId === id),
    );
    const measureToUpdate: UpdatePlanningMeasureRequest[] = [];
    let selectedMappingId = emptyGuid;
    selectedMappingId = selectedIdForAvailableParameters;
    d.mainObjectId = selectedMappingId;
    d.planningMeasureParameterIds = selectedParameterIdsToUpdate;
    d.planningMeasureDone = isPlanningMeasureClosed;
    d.finishedYear = isPlanningMeasureClosed ? d.finishedYear : null;
    d.planningMeasureFinishedCost = isPlanningMeasureClosed ? d.planningMeasureFinishedCost : null;
    measureToUpdate.push(d);
    updateMeasure({ request: measureToUpdate });
  };

  const hasUnsavedChanges = () => {
    const currentValues = getValues();
    return JSON.stringify(currentValues) !== JSON.stringify(initialValues);
  };

  const handleClose = () => {
    if (hasUnsavedChanges()) {
      setShowConfirmDialog(true);
    } else {
      onHide();
    }
  };

  const confirmClose = () => {
    setShowConfirmDialog(false);
    onHide();
  };

  const cancelClose = () => {
    setShowConfirmDialog(false);
  };

  const onSubmit = (data: UpdatePlanningMeasureRequest) => {
    updatePlanningMeasure(data);
  };

  const checkErrors = () => {
    if (isError || availableParametersIsError) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <h5 className="mb-0">{t('measureOverview.updateMeasure')}</h5>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <>
              <Row className="border-bottom mb-3">
                <Col>
                  <div className="d-flex justify-content-between">
                    <h5 className="mb-3">Plan detaljer</h5>
                    <p className="mb-0">*Man kan kun endre på det nivået tiltaket er opprettet på.</p>
                  </div>
                  <Row className="mb-3">
                    <Col>
                      <Form.Group>
                        <Form.Label>{type?.levels?.find((level) => level.level === 2)?.systemName}</Form.Label>
                        <Select
                          isDisabled={planningMeasure?.mainObjectName !== ''}
                          options={convertedParentObjectOptions}
                          value={
                            selectedParentId !== ''
                              ? parentObjectOptions
                                  ?.filter((x) => x.objectId === selectedParentId)
                                  ?.map((x) => ({ value: x.objectId, label: x.systemName }))[0]
                              : planningMeasure?.parentObjectName
                              ? { value: selectedParentId, label: planningMeasure?.parentObjectName }
                              : { value: '', label: 'Ingen verdi' }
                          }
                          onChange={(selectedOption) => {
                            const newParentId = selectedOption?.value || '';
                            setSelectedParentId(newParentId);
                            setSelectedIdForAvailableParameters(newParentId);
                          }}
                        ></Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>{type?.levels?.find((level) => level.level === 3)?.systemName}</Form.Label>
                        <Select
                          isDisabled={planningMeasure?.mainObjectName === '' || planningMeasure?.childObjectName !== ''}
                          options={convertedMainObjectOptions}
                          value={
                            selectedMainId !== ''
                              ? mainObjectOptions
                                  ?.filter((x) => x.objectId === selectedMainId)
                                  ?.map((x) => ({ value: x.objectId, label: x.systemName }))[0]
                              : planningMeasure?.mainObjectName
                              ? { value: selectedParentId, label: planningMeasure?.mainObjectName }
                              : { value: '', label: 'Ingen verdi' }
                          }
                          onChange={(selectedOption) => {
                            const newMainId = selectedOption?.value || '';
                            setSelectedMainId(newMainId);
                            setSelectedIdForAvailableParameters(newMainId);
                          }}
                        ></Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Form.Group>
                        <Form.Label>{type?.levels?.find((level) => level.level === 4)?.systemName}</Form.Label>
                        <Select
                          isDisabled={planningMeasure?.childObjectName == ''}
                          options={convertedChildObjectOptions}
                          value={
                            selectedChildId !== ''
                              ? childObjectOptions
                                  ?.filter((x) => x.objectId === selectedChildId)
                                  ?.map((x) => ({ value: x.objectId, label: x.systemName }))[0]
                              : planningMeasure?.childObjectName
                              ? { value: selectedChildId, label: planningMeasure?.childObjectName }
                              : { value: '', label: 'Ingen verdi' }
                          }
                          onChange={(selectedOption) => {
                            const newChildId = selectedOption?.value || '';
                            setSelectedChildId(newChildId);
                            setSelectedIdForAvailableParameters(newChildId);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={8}>
                      <Form.Group>
                        <Form.Label>Tiltaksnavn</Form.Label>
                        {errors.systemName && <span className="ps-2 text-danger">*</span>}
                        <Form.Control
                          placeholder="Oppgi et prosjektnavn"
                          {...register('systemName', { required: true })}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>Tiltaksnummer</Form.Label>
                        {errors.planningMeasureNumber && <span className="ps-2 text-danger">*</span>}
                        <Form.Control placeholder="Eksempel: 123" {...register('planningMeasureNumber')}></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>Hovedkategori</Form.Label>
                        <Form.Select {...register('planningMeasureMainCategoryId', { required: true })}>
                          <option value={PlanningMeasureMainCategory.Security} selected>
                            {t(PlanningMeasureMainCategory.Security)}
                          </option>
                          <option value={PlanningMeasureMainCategory.Maintenance}>
                            {t(PlanningMeasureMainCategory.Maintenance)}
                          </option>
                          <option value={PlanningMeasureMainCategory.Aesthetic}>
                            {t(PlanningMeasureMainCategory.Aesthetic)}
                          </option>
                          <option value={PlanningMeasureMainCategory.Environment}>
                            {t(PlanningMeasureMainCategory.Environment)}
                          </option>
                          <option value={PlanningMeasureMainCategory.ClimateAdaption}>
                            {t(PlanningMeasureMainCategory.ClimateAdaption)}
                          </option>
                          <option value={PlanningMeasureMainCategory.UniversalDesign}>
                            {t(PlanningMeasureMainCategory.UniversalDesign)}
                          </option>
                          <option value={PlanningMeasureMainCategory.Functionality}>
                            {t(PlanningMeasureMainCategory.Functionality)}
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>Konsekvensgrad</Form.Label>
                        <Form.Select {...register('consequenceDegree', { valueAsNumber: true })}>
                          <option value={0} selected>
                            0
                          </option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>Prioritet</Form.Label>
                        {errors.planningPriorityId && <span className="ps-2 text-danger">*</span>}
                        <Form.Select {...register('planningPriorityId', { required: true })}>
                          <option value={PlanningPriority.High}>{t(PlanningPriority.High)}</option>
                          <option value={PlanningPriority.Medium}>{t(PlanningPriority.Medium)}</option>
                          <option value={PlanningPriority.Low} selected>
                            {t(PlanningPriority.Low)}
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Form.Group>
                        <Form.Label>Beskrivelse</Form.Label>
                        <Form.Control
                          placeholder="Oppgi en beskrivelse"
                          as="textarea"
                          {...register('systemDescription')}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {checkErrors() && (
                    <Row>
                      <Col>
                        <p className="text-danger">Ikke alle felter er fylt ut, feltene er markert med *</p>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col>
                  <Flex justify="space-between" align="center" wrap="wrap" mb="sm" pr="sm" gap={'6px'}>
                    <h5 style={{ marginBottom: '0' }}>Dagens status og parameter</h5>
                    <Input size="sm" placeholder={t('search')} w={'150px'} onChange={handleChange} />
                  </Flex>

                  <Row style={{ height: '500px', overflow: 'scroll' }}>
                    <Col xs={12}>
                      <>
                        <LoadingSpinner isLoading={availableParametersIsLoading} />
                        {availableParameters &&
                          filteredParameters.map((cd: any, index: any) => (
                            <Card className="p-3 mb-3" key={index}>
                              <Row>
                                <Col className="d-flex align-items-center">
                                  <Form.Check
                                    value={cd.mappingParameterId}
                                    label={cd.mappingParameterName}
                                    checked={selectedParameterIds?.some((id) => id === cd.mappingParameterId)}
                                    onChange={() => handleCardSelection(cd.mappingParameterId)}
                                  />
                                </Col>
                                {
                                  <Col className="d-flex justify-content-end align-items-center">
                                    <small className="text-muted me-2" style={{ fontSize: '0.8rem' }}>
                                      {cd.mappingSetName}
                                    </small>
                                    <div className="d-flex align-items-center">
                                      <ConditionDegreeIcon conditionDegree={cd.currentParameterValue!} />
                                      <small className="ms-1">
                                        {cd.currentParameterValue! !== 99
                                          ? cd.currentParameterValue?.toFixed(1)
                                          : 'N/A'}{' '}
                                        {cd.previousParameterValue! != null
                                          ? `(${cd.previousParameterValue?.toFixed(1)})`
                                          : ''}
                                      </small>
                                    </div>
                                  </Col>
                                }
                              </Row>
                            </Card>
                          ))}
                      </>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <div className="d-flex justify-content-between">
                    <h5>Oppstart og kostnad</h5>
                    <p className="mb-0">*Oppstart og kostnad redigeres rett i undertabell i oversikten.</p>
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                {isPlanningMeasureClosed === true ? (
                  <Col className="d-flex justify-content-end align-items-end">
                    <Button
                      variant="tertiary"
                      size="sm"
                      onClick={() => {
                        setIsPlanningMeasureClosed(false);
                      }}
                    >
                      Åpne tiltak
                    </Button>
                  </Col>
                ) : (
                  <Col className="d-flex justify-content-end align-items-end">
                    <>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          setIsPlanningMeasureClosed(true);
                        }}
                      >
                        Avslutt tiltak
                      </Button>
                    </>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Planlagt startår</Form.Label>
                    <Form.Control type="number" value={planningMeasure?.startYear} readOnly></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Planlagt sluttår</Form.Label>
                    <Form.Control type="number" value={planningMeasure?.endYear} readOnly></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Estimert pris</Form.Label>
                    <Form.Control type="number" value={planningMeasure?.totalEstimateCost} readOnly></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Kalkulert pris</Form.Label>
                    <Form.Control type="number" value={planningMeasure?.totalCalculatedCost} readOnly></Form.Control>
                  </Form.Group>
                </Col>
                {isPlanningMeasureClosed === true && (
                  <>
                    <Col>
                      <Form.Group>
                        <Form.Label>Avsluttet år</Form.Label>
                        <Form.Select {...register(`finishedYear`, { valueAsNumber: true })}>
                          {arrayRange(planningMeasure?.startYear ?? 2000, 2100, 1).map((year, yearIndex) => (
                            <option key={yearIndex} value={year}>
                              {year}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Avsluttet pris</Form.Label>
                        <Form.Control type="number" {...register(`planningMeasureFinishedCost`)}></Form.Control>
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
            </>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                reset();
                onHide();
              }}
            >
              {t('buttons.cancel')}
            </Button>
            <Button onClick={handleSubmit(onSubmit)}>{t('buttons.update')}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {showConfirmDialog && (
        <Modal show={true} onHide={cancelClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('measureOverview.unsavedChangesTitle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('measureOverview.unsavedChangesText')}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelClose}>
              {t('buttons.cancel')}
            </Button>
            <Button variant="danger" onClick={confirmClose}>
              {t('buttons.discardChanges')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
