import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import '@mantine/core/styles.css';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { McDesignProvider } from '@multiconsult-group/core';
import '@multiconsult-group/core/styles.css';
import { AllEnterpriseModule, LicenseManager, ModuleRegistry } from 'ag-grid-enterprise';
import { AuthenticatedUserProvider } from 'common/contexts/AuthenticatedUserContext';
import { RegisterErrorCallback } from 'common/helpers/error-handling/ErrorHandling';
import { notify } from 'common/helpers/toast-notification-helper';
import { useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom';

import { CustomNavigationClient } from '../auth/CustomNavigationClient';
import { Navbar } from '../common/components/Navbar';
import Router from '../router/Router';
import { AdminProvider } from './AdminContext';
import styles from './App.module.scss';
import './App.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: styles.colorPrimary,
    },
    secondary: {
      main: styles.colorSecondary,
    },
    // tertiary: {
    //   main: styles.colorTertiary,
    // },
    error: {
      main: styles.colorError,
    },
    warning: {
      main: styles.colorWarning,
    },
    info: {
      main: styles.colorInfo,
    },
    success: {
      main: styles.colorSuccess,
    },
  },
});

LicenseManager.setLicenseKey(
  'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-059505}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Multiconsult}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{MCApps}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{MCApps}_need_to_be_licensed___{MCApps}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{6_May_2025}____[v3]_[0102]_MTc0NjQ4NjAwMDAwMA==a97d592e91289cf9525d2d4e5c1de16b',
);

type IProps = {
  pca: PublicClientApplication;
};

function App(props: IProps) {
  const { pca } = props;
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  useEffect(() => {
    RegisterErrorCallback((err) => {
      notify('danger', err);
    });
    ModuleRegistry.registerModules([AllEnterpriseModule]);
  }, []);

  return (
    <MsalProvider instance={pca}>
      <McDesignProvider>
        <MuiThemeProvider theme={theme}>
          <AuthenticatedUserProvider>
            <AdminProvider>
              <div className="vh-100 d-flex flex-column" style={{ overflow: 'hidden' }}>
                <div>
                  <Navbar></Navbar>
                </div>
                <ReactNotifications />
                <div className="flex-grow-1 pb-4" style={{ overflow: 'auto' }} id="main-div">
                  <Router />
                </div>
              </div>
            </AdminProvider>
          </AuthenticatedUserProvider>
        </MuiThemeProvider>
      </McDesignProvider>
    </MsalProvider>
  );
}

export default App;
