import { AxiosError } from 'axios';
import { planningApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { PlanningMeasureResponse, ValidationProblemDetails } from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type UpdateParams = {
  planningMeasureCostId: string;
};

function useRemovePlanningMeasureCost(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  boolean | undefined,
  (params: UpdateParams) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    ({ planningMeasureCostId }: UpdateParams) => {
      return planningApi
        .planningPlanningMeasureCostPlanningMeasureCostIdDelete(planningMeasureCostId)
        .then((x) => x.data);
    },
    {
      onMutate: async (params: UpdateParams) => {
        await queryClient.cancelQueries(QueryKeys.PlanningMeasures);

        const previousData = queryClient.getQueryData<PlanningMeasureResponse[] | undefined>(
          QueryKeys.PlanningMeasures,
        );

        queryClient.setQueryData<PlanningMeasureResponse[] | undefined>(QueryKeys.PlanningMeasures, (oldData) => {
          if (!oldData) return undefined;

          const newData = oldData.map((pm) => {
            if (pm.planningMeasureCosts) {
              const removedCost = pm.planningMeasureCosts.find(
                (x) => x.planningMeasureCostId == params.planningMeasureCostId,
              );

              if (removedCost) {
                const index = pm.planningMeasureCosts.indexOf(removedCost);

                if (index > -1) {
                  pm.planningMeasureCosts.splice(index, 1);
                }
              }
            }

            pm.totalEstimateCost = pm.planningMeasureCosts!.reduce((total, cost) => total + cost.estimateCost, 0);
            pm.totalCalculatedCost = pm.planningMeasureCosts!.reduce(
              (total, cost) => total + (cost.calculatedCost ?? 0),
              0,
            );

            return pm;
          });

          return newData;
        });

        // Return a context to rollback the optimistic update if the mutation fails
        return { previousData };
      },
      onError: (err, params, context) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }

        queryClient.setQueryData(QueryKeys.PlanningMeasures, context?.previousData);
      },
      onSettled: () => {
        // Refetch the data to ensure it’s up-to-date with the server
        queryClient.invalidateQueries(QueryKeys.PlanningMeasures);
      },
    },
  );

  const remove = (params: UpdateParams) => mutate(params);

  return [isLoading, isError, validationError, data, remove];
}

export default useRemovePlanningMeasureCost;
