import { ReactNode, createContext, useContext, useState } from 'react';

interface ProjectContextProps {
  selectedProjectIdToFilterOn: string | null;
  setSelectedProjectIdToFilterOn: (id: string | null) => void;
}

const ProjectContext = createContext<ProjectContextProps>({
  selectedProjectIdToFilterOn: null,
  setSelectedProjectIdToFilterOn: () => {},
});

export const useProjectContext = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const [selectedProjectIdToFilterOn, setSelectedProjectIdToFilterOn] = useState<string | null>(null);

  return (
    <ProjectContext.Provider value={{ selectedProjectIdToFilterOn, setSelectedProjectIdToFilterOn }}>
      {children}
    </ProjectContext.Provider>
  );
};
