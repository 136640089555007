import { Box, Group } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import { ArcElement, BarElement, CategoryScale, Chart, Legend, LinearScale, Tooltip } from 'chart.js';
import { PlanningMeasureResponse } from 'common/api/multimap/api';
import { groupBy } from 'common/helpers/array.helpers';
import React from 'react';
import { RefObject } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

Chart.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

interface PlanningMeasuresDashboardProps {
  planningMeasures: PlanningMeasureResponse[];
  gridRef?: RefObject<{ api: GridApi }>;
}

const PlanningMeasuresDashboard: React.FC<PlanningMeasuresDashboardProps> = ({ planningMeasures, gridRef }) => {
  const { t } = useTranslation('planning');

  const translatedTitle = (title: any) => {
    return (t(title) || title) as string;
  };

  const generateCategoryCounts = (
    measures: PlanningMeasureResponse[],
    key: (measure: PlanningMeasureResponse) => string,
  ): { [key: string]: number } => {
    const counts: { [key: string]: number } = {};
    measures.forEach((measure) => {
      const id = key(measure);
      counts[id] = (counts[id] || 0) + 1;
    });
    return counts;
  };

  const generateCostCounts = (measures: PlanningMeasuresDashboardProps['planningMeasures'], topN = 20) => {
    const costData = measures.flatMap((measure) => measure.planningMeasureCosts);
    const groupedCosts: { [key: string]: any[] } = groupBy(costData, (item) => item?.startYear ?? '');
    const totalCostsByYear = Object.keys(groupedCosts).map((year) => {
      let totalCost = 0;
      groupedCosts[year].forEach((cost: any) => {
        if (cost.estimateCost !== undefined && typeof cost.estimateCost === 'number') {
          totalCost += cost.estimateCost;
        }
      });

      return {
        year,
        totalCost: Math.round(totalCost),
      };
    });
    const yearSlice = totalCostsByYear.slice(0, topN);
    return {
      labels: yearSlice.map((item) => item.year),
      datasets: [
        {
          label: '', // No label for dataset
          data: yearSlice.map((item) => item.totalCost),
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
        },
      ],
    };
  };

  const mainCategoryCounts = generateCategoryCounts(
    planningMeasures,
    (m) => m.planningMeasureMainCategoryId ?? 'Unknown',
  );
  const priorityCounts = generateCategoryCounts(planningMeasures, (m) => m.planningPriorityId ?? 'Unknown');
  const consequenceCounts = generateCategoryCounts(
    planningMeasures,
    (m) => m.consequenceDegree?.toString() ?? 'Unknown',
  );

  function generateChartData(counts: { [key: string]: number }) {
    const keys: string[] = Object.keys(counts);
    return {
      labels: keys.map((key) => translatedTitle(key)),
      datasets: [
        {
          // Optional label for the dataset
          label: '',
          data: keys.map((key) => counts[key]),
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
          hoverBackgroundColor: ['#FF6384CC', '#36A2EBCC', '#FFCE56CC', '#4BC0C0CC', '#9966FFCC', '#FF9F40CC'],
        },
      ],
      rawKeys: keys,
    };
  }

  const mainCategoryData = generateChartData(mainCategoryCounts);
  const priorityData = generateChartData(priorityCounts);
  const consequenceData = generateChartData(consequenceCounts);
  const costCountsData = generateCostCounts(planningMeasures);

  const pieOptions = (chartData: ReturnType<typeof generateChartData>, columnTitle: string) => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'bottom' as const,
      },
    },
    onHover: (event: any, elements: any[]) => {
      event.native.target.style.cursor = elements.length ? 'pointer' : 'default';
    },
    onClick: (event: any, elements: any[]) => {
      if (!elements || elements.length === 0) return;
      const index = elements[0].index;
      const rawKey = chartData.rawKeys[index];
      if (gridRef?.current?.api) {
        const currentFilter = gridRef.current.api.getFilterModel()[columnTitle];
        let newValues = currentFilter?.values || [];
        if (newValues.includes(rawKey)) {
          newValues = newValues.filter((value: string) => value !== rawKey);
        } else {
          newValues.push(rawKey);
        }
        gridRef.current.api.setColumnFilterModel(columnTitle, {
          filterType: 'set',
          values: newValues,
        });
        gridRef.current.api.onFilterChanged();
      }
    },
  });

  return (
    <Group mb={14} style={{ width: '100%' }} justify="flex-end">
      <Box w={'160px'}>
        <h5>{t('measureOverview.planningMeasureMainCategoryId')}</h5>
        <div style={{ height: '150px', width: '100%' }}>
          <Pie data={mainCategoryData} options={pieOptions(mainCategoryData, 'planningMeasureMainCategoryId')} />
        </div>
      </Box>
      <Box w={'160px'}>
        <h5>{t('priorityId')}</h5>
        <div style={{ height: '150px', width: '100%' }}>
          <Pie data={priorityData} options={pieOptions(priorityData, 'planningPriorityId')} />
        </div>
      </Box>
      <Box w={'160px'}>
        <h5>{t('consequenceDegree')}</h5>
        <div style={{ height: '150px', width: '100%' }}>
          <Pie data={consequenceData} options={pieOptions(consequenceData, 'consequenceDegree')} />
        </div>
      </Box>
      <Box style={{ maxWidth: '400px', flexGrow: 1 }}>
        <h5>Estimert Kost</h5>
        <div style={{ height: '150px', width: '100%' }}>
          <Bar
            data={costCountsData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />{' '}
        </div>
      </Box>
    </Group>
  );
};

export default PlanningMeasuresDashboard;
