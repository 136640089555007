import { planningApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import { PlanningMeasureResponse } from 'common/api/multimap';
import { Guid } from 'common/types/guid.type';
import { useQuery } from 'react-query';

function useGetPlanningMeasures(
  id: Guid,
): [boolean, boolean, PlanningMeasureResponse[] | undefined, () => Promise<PlanningMeasureResponse[] | undefined>] {
  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    [QueryKeys.PlanningMeasures],
    () => planningApi.planningPlanningMeasureWorkspaceWorkspaceIdGet(id).then((x) => x.data),
    {
      enabled: !!id,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const fetch = () => refetch().then((x) => x.data);

  return [isLoading || isFetching, isError, data, fetch];
}

export default useGetPlanningMeasures;
