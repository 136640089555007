import { SearchOutlined } from '@mui/icons-material';
import { UpdateUserRequest, UserResponse } from 'common/api/multimap';
import { TableContainer } from 'common/components/TableContainer/TableContainer';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { asDateTime } from 'common/helpers/dateHelpers';
import { useState } from 'react';
import { Button, Form, InputGroup, Modal, Stack, Table } from 'react-bootstrap';

import useGetOrganizations from '../organizations/hooks/useGetOrganizations';
import useGetUsers from './hooks/useGetUsers';
import useUpdateUser from './hooks/useUpdateUser';

export const Users: React.FC = () => {
  const [filter, setFilter] = useState<string>('');

  const [organizationFilter, setOrganizationFilter] = useState<string>('');

  const [isLoading, , data] = useGetUsers();

  const [isLoadingOrganizations, , organizations] = useGetOrganizations();

  const [showModal, setShowModal] = useState<boolean>(false);

  const [user, setUser] = useState<UserResponse>();

  const [, , , , update] = useUpdateUser();

  const getOrganizationName = (id: string) => {
    if (organizations) {
      const organization = organizations.find((x) => x.organizationId == id);

      if (organization == undefined) {
        return 'Ikke tilknyttet organisasjon';
      } else {
        return organization.name;
      }
    }
  };
  return (
    <>
      <PageTitle title="Brukere"></PageTitle>
      <TableContainer>
        <Stack direction="horizontal" className="table-filter">
          <InputGroup>
            <InputGroup.Text>
              <SearchOutlined fontSize="small" />
            </InputGroup.Text>
            <Form.Control
              type="text"
              id="filter"
              placeholder={'Søk på bruker'}
              aria-describedby={'Søk på bruker'}
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
        </Stack>
        <Table hover responsive>
          <thead>
            <tr>
              <th scope="col">Fornavn</th>
              <th scope="col">Etternavn</th>
              <th scope="col">E-post</th>
              <th scope="col">Organisasjon</th>
              <th scope="col">Rettigheter</th>
              <th scope="col" className="text-nowrap">
                Dato opprettet
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                .filter((x) => x.firstname?.toLowerCase().includes(filter.toLowerCase()))
                .sort((a, b) => {
                  const textA = a.firstname ? a.firstname.toUpperCase() : '';
                  const textB = b.firstname ? b.firstname.toUpperCase() : '';
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                })
                .map((row, i) => (
                  <tr key={i}>
                    <td>
                      <span>{row.firstname}</span>
                    </td>
                    <td>
                      <span>{row.lastname}</span>
                    </td>
                    <td>
                      <span>{row.email}</span>
                    </td>
                    <td>
                      <span>{getOrganizationName(row.organizationId)}</span>
                    </td>
                    <td>
                      <span>
                        {row.isOrganizationAdministrator == false
                          ? 'Organisasjonsbruker'
                          : 'Organisasjonsadministrator'}
                      </span>
                    </td>
                    <td>
                      <span>{asDateTime(row.createdOn)}</span>
                    </td>
                    <td>
                      <Button
                        variant="tertiary"
                        size="sm"
                        onClick={() => {
                          setUser(row);
                          setShowModal(true);
                        }}
                      >
                        Endre organisasjon
                      </Button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </TableContainer>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Endre på brukerorganisasjon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack direction="vertical" gap={3}>
            <InputGroup>
              <InputGroup.Text>
                <SearchOutlined fontSize="small" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                id="filter"
                placeholder="Søk på organisasjon"
                onChange={(e) => setOrganizationFilter(e.target.value)}
              />
            </InputGroup>
          </Stack>
          <div className="overflow-scroll mt-3" style={{ maxHeight: '500px' }}>
            <Table hover responsive>
              <thead>
                <tr>
                  <th scope="col">Organisasjonsnavn</th>
                  <th scope="col">Organisasjonsnummer</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {organizations &&
                  organizations
                    .filter((x) => x.name?.toLowerCase().includes(organizationFilter.toLowerCase()))
                    .sort((a, b) => {
                      if (a.name! < b.name!) {
                        return -1;
                      }
                      if (a.name! > b.name!) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((row, i) => {
                      return (
                        <tr key={i}>
                          <td>{row.name}</td>
                          <td>{row.organizationNumber}</td>
                          <td>
                            <Button
                              variant="tertiary"
                              size="sm"
                              className="text-nowrap"
                              onClick={() => {
                                if (user) {
                                  const request: UpdateUserRequest = {
                                    userId: user.userId,
                                    organizationId: row.organizationId,
                                    isOrganizationAdministrator: false,
                                  };
                                  update({ userId: user.userId, request: request });
                                  setShowModal(false);
                                }
                              }}
                            >
                              Overfør som organisasjonsbruker
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant="tertiary"
                              size="sm"
                              className="text-nowrap"
                              onClick={() => {
                                if (user) {
                                  const request: UpdateUserRequest = {
                                    userId: user.userId,
                                    organizationId: row.organizationId,
                                    isOrganizationAdministrator: true,
                                  };
                                  update({ userId: user.userId, request: request });
                                  setShowModal(false);
                                }
                              }}
                            >
                              Overfør som organisasjonsadministrator
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
