import { OrganizationResponse } from 'common/api/multimap';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import useUpdateOrganizationStatus from '../../hooks/useUpdateOrganizationStatus';

interface StatusModalProps {
  open: boolean;
  onClose: () => void;
  org: OrganizationResponse | null;
}

const StatusModal: React.FC<StatusModalProps> = ({ open, onClose, org }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'organizations.organizations' });

  const [isLoading, , , , update] = useUpdateOrganizationStatus(onClose);

  const changeStatus = async () => {
    update({
      id: org?.organizationId as string,
      request: { organizationId: org?.organizationId, isActivate: org?.isActive ? 'false' : 'true' },
    });
  };

  return (
    <>
      <Modal
        show={open}
        onHide={() => {
          onClose();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {' '}
            {org?.isActive ? t('statusModal.deactivate.title') : t('statusModal.activate.title')}{' '}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-4">
          {org?.isActive
            ? t('statusModal.deactivate.deactivatePrompt', { name: org?.name })
            : t('statusModal.activate.activatePrompt', { name: org?.name })}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="tertiary" onClick={() => {}} disabled={isLoading}>
            {t('statusModal.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              changeStatus();
            }}
            disabled={isLoading}
          >
            {isLoading ? <Spinner animation="border" role="status" size="sm"></Spinner> : t('statusModal.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StatusModal;
