import { AxiosError } from 'axios';
import { planningApi } from 'common/api/MultimapClients';
import QueryKeys from 'common/api/QueryKeys';
import {
  PlanningMeasureCostResponse,
  PlanningMeasureResponse,
  UpdatePlanningMeasureCostRequest,
  ValidationProblemDetails,
} from 'common/api/multimap/api';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export type UpdateParams = {
  planningMeasureId: string;
  request: UpdatePlanningMeasureCostRequest[];
};

function useUpdatePlanningMeasureCost(): [
  boolean,
  boolean,
  ValidationProblemDetails | undefined,
  PlanningMeasureCostResponse[] | undefined,
  (params: UpdateParams) => void,
] {
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<ValidationProblemDetails | undefined>();

  const { isLoading, isError, data, mutate } = useMutation(
    ({ planningMeasureId, request }: UpdateParams) => {
      return planningApi
        .planningPlanningMeasurePlanningMeasureIdPlanningMeasureCostPut(planningMeasureId, request)
        .then((x) => x.data);
    },
    {
      onMutate: async (params: UpdateParams) => {
        await queryClient.cancelQueries(QueryKeys.PlanningMeasures);

        const previousData = queryClient.getQueryData<PlanningMeasureResponse[] | undefined>(
          QueryKeys.PlanningMeasures,
        );

        queryClient.setQueryData<PlanningMeasureResponse[] | undefined>(QueryKeys.PlanningMeasures, (oldData) => {
          if (!oldData) return undefined;

          const newData = oldData.map((pm) => {
            pm.planningMeasureCosts?.map((pmc) => {
              const updatedCost = params.request.find((x) => x.planningMeasureCostId == pmc.planningMeasureCostId);

              if (updatedCost) {
                pmc.estimateCost = updatedCost.estimateCost!;
                pmc.calculatedCost = updatedCost.calculatedCost;
              }

              return pmc;
            });

            pm.totalEstimateCost = pm.planningMeasureCosts!.reduce((total, cost) => total + cost.estimateCost, 0);
            pm.totalCalculatedCost = pm.planningMeasureCosts!.reduce(
              (total, cost) => total + (cost.calculatedCost ?? 0),
              0,
            );

            return pm;
          });

          return newData;
        });

        // Return a context to rollback the optimistic update if the mutation fails
        return { previousData };
      },
      onError: (err, params, context) => {
        const axiosErr = err as AxiosError;
        if (axiosErr?.response?.status === 422) {
          setValidationError(axiosErr.response?.data as ValidationProblemDetails);
        }

        queryClient.setQueryData(QueryKeys.PlanningMeasures, context?.previousData);
      },
      onSettled: () => {
        // Refetch the data to ensure it’s up-to-date with the server
        queryClient.invalidateQueries(QueryKeys.PlanningMeasures);
      },
    },
  );

  const updateMeasure = (params: UpdateParams) => mutate(params);

  return [isLoading, isError, validationError, data, updateMeasure];
}

export default useUpdatePlanningMeasureCost;
