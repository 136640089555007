import { ActionIcon, Button, Flex, Input } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { CellValueChangedEvent, ColDef, SideBarDef } from 'ag-grid-enterprise';
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import useAddOrganizationServiceType from 'common/api/hooks/useAddOrganizationServiceType';
import useGetOrganizationServiceTypes from 'common/api/hooks/useGetOrganizationServiceTypes';
import {
  OrganizationServiceTypeResponse,
  PlanningMeasureMainCategory,
  PlanningMeasureResponse,
  PlanningPriority,
  UpdatePlanningMeasureCostRequest,
  UpdatePlanningMeasureRequest,
  WorkspaceResponse,
} from 'common/api/multimap';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import useGetObjectTypeDetail from 'features/admin/object-types/hooks/useGetObjectTypeDetail';
import useGetWorkspaceDetail from 'features/admin/workspaces/hooks/useGetWorkspaceDetail';
import { AG_GRID_LOCALE_NOR } from 'locales/agGridLocale';
import { useMemo, useRef, useState } from 'react';
import { Modal, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdContentCopy, MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { CreatePlanningMeasureModal } from '../components/CreatePlanningMeasureModal';
import PlanningMeasuresDashboard from '../components/PlanningMeasureDashboard';
import { useProjectContext } from '../components/ProjectContext';
import { UpdatePlanningMeasureModal } from '../components/UpdatePlanningMeasureModal';
import useAddPlanningMeasureCost from '../hooks/useAddPlanningMeasureCost';
import useGetPlanningMeasures from '../hooks/useGetPlanningMeasures';
import useGetPlanningProjects from '../hooks/useGetPlanningProjects';
import useRemovePlanningMeasure from '../hooks/useRemovePlanningMeasure';
import useRemovePlanningMeasureCost from '../hooks/useRemovePlanningMeasureCost';
import useUpdatePlanningMeasure from '../hooks/useUpdatePlanningMeasure';
import useUpdatePlanningMeasureCost from '../hooks/useUpdatePlanningMeasureCost';

interface PlanningCost {
  planningMeasureCostId: string;
  calculatedCost: number;
  estimateCost: number;
  startYear: number;
}

export const PlanningOverview = () => {
  const [workspace] = useLocalStorage<WorkspaceResponse | undefined>('workspace', undefined);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showCopyModal, setShowCopyModal] = useState<boolean>(false);
  const [measureToDelete, setMeasureToDelete] = useState<string | null>('');
  const [measureToCopy, setMeasureToCopy] = useState<string | null>('');
  const [activeMeasure, setActiveMeasure] = useState<string | null>('');
  const [showSubscribeModal, setShowSubscribeModal] = useState<boolean>(false);
  const [planningMeasureFilter, setPlanningMeasureFilter] = useState<'OPEN' | 'CLOSED' | 'ALL'>('ALL');
  const [quickFilterText, setQuickFilterText] = useDebouncedState('', 400);
  const { selectedProjectIdToFilterOn } = useProjectContext();
  const gridRef = useRef<any>(null);

  const { t } = useTranslation('planning');

  const [isLoading, isError, planningMeasures] = useGetPlanningMeasures(workspace?.workspaceId ?? '');
  const [projectsIsLoading, projectsIsError, allProjects] = useGetPlanningProjects(workspace?.workspaceId ?? '');
  const [, , , isPlanningMeasureUpdated, updateMeasure] = useUpdatePlanningMeasure();
  const [, , , isPlanningMeasureCostUpdated, updateCost] = useUpdatePlanningMeasureCost();
  const [, , , isPlanningMeasureCostAdded, addCost] = useAddPlanningMeasureCost();
  const [, , , isPlanningMeasureCostRemoved, removeCost] = useRemovePlanningMeasureCost();
  const [, , , isPlanningMeasureRemoved, removeMeasure] = useRemovePlanningMeasure();
  const [serviceTypeLoading, , serviceTypes] = useGetOrganizationServiceTypes(workspace?.organizationId ?? '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFilterText(e.currentTarget.value);
  };

  let filteredPlanningMeasures;

  switch (planningMeasureFilter) {
    case 'OPEN':
      filteredPlanningMeasures = planningMeasures?.filter((measure) => !measure.planningMeasureDone);
      break;
    case 'CLOSED':
      filteredPlanningMeasures = planningMeasures?.filter((measure) => measure.planningMeasureDone);
      break;
    case 'ALL':
    default:
      filteredPlanningMeasures = planningMeasures;
      break;
  }
  const [workspaceDetailIsLoading, workspaceDetailIsError, workspaceDetail] = useGetWorkspaceDetail(
    workspace?.workspaceId ?? '',
  );

  const [typeIsLoading, , type] = useGetObjectTypeDetail(
    workspaceDetail ? workspaceDetail.objectType.objectTypeId : '',
  );

  const [, , , result, add] = useAddOrganizationServiceType();

  const findPlanningServiceType = (services: OrganizationServiceTypeResponse[]) => {
    const notEmpty = services.filter((x) => x.serviceType == 'Planning');

    if (notEmpty.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const toggleAllRows = () => {
    const gridApi = gridRef.current.api;
    gridApi.forEachNode((node: any) => {
      node.setExpanded(!isExpanded);
    });
    setIsExpanded(!isExpanded); // Toggle the expand/collapse state
  };
  const sideBar = useMemo(() => {
    const sb: SideBarDef = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
            // Other parameters...
          },
        },
      ],
      position: 'left',
    };
    return sb;
  }, []);
  const CustomHeader = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        radius={'4px'}
        w={'2em'}
        ml={'-.25em'}
        mr={'.5em'}
        px={0}
        size="compact-xs"
        variant="subtle"
        color="black"
        onClick={(e: any) => {
          e.stopPropagation();
          toggleAllRows();
        }}
      >
        <span
          className={`${isExpanded ? 'ag-icon ag-icon-tree-open' : 'ag-icon ag-icon-tree-closed'}`}
          unselectable="on"
          role="presentation"
        ></span>
      </Button>
      <span style={{ marginRight: '8px' }}>{t('measureOverview.systemName')}</span>
    </div>
  );
  const renderTranslatedValue = (params: CustomCellRendererProps) => {
    return (t(params.value) || params.value) as string;
  };
  const formatNumber = (value: number | null) => {
    if (value == null) return t('noValue');
    return new Intl.NumberFormat('nb-NO').format(value);
  };

  const handleDeleteMeasure = (measureId: string) => {
    removeMeasure({ id: measureId });
  };

  const handleUpdateMeasure = (measureId: string) => {
    setActiveMeasure(measureId ?? '');
    setShowUpdateModal(true);
  };

  const onMeasureCellValueChanged = (event: CellValueChangedEvent) => {
    let planningProjectId;
    if (event.source === 'paste') {
      planningProjectId = allProjects?.find(
        (project) => project.systemName === event.data.planningProjectId,
      )?.planningProjectId;
    } else {
      planningProjectId = event.data.planningProjectId;
    }
    const request: UpdatePlanningMeasureRequest[] = [
      {
        planningMeasureId: event.data.planningMeasureId,
        planningProjectId: planningProjectId,
        systemName: event.data.systemName,
        systemDescription: event.data.systemDescription,
        planningMeasureNumber: event.data.planningMeasureNumber,
        planningPriorityId: event.data.planningPriorityId,
        planningMeasureMainCategoryId: event.data.planningMeasureMainCategoryId,
        planningMeasureDone: false,
        planningMeasureFinishedCost: null,
        finishedYear: null,
        consequenceDegree: event.data.consequenceDegree,
        mainObjectId: event.data.mainObjectId,
      },
    ];
    updateMeasure({ request: request });
  };

  const columns: ColDef<PlanningMeasureResponse>[] = [
    {
      headerName: 'Tiltaksnr',
      field: 'planningMeasureNumber',
      hide: true,
    },
    {
      headerName: t('measureOverview.systemName'),
      field: 'systemName',
      lockPosition: true,
      editable: true,
      cellRenderer: 'agGroupCellRenderer',
      cellClass: 'clickable',
      headerComponent: CustomHeader,
      pinned: 'left',
      onCellClicked: (params) => {
        handleUpdateMeasure(params?.data?.planningMeasureId ?? '');
      },
    },
    {
      headerName: type?.levels?.find((level) => level.level === 2)?.systemName,
      filter: true,
      field: 'parentObjectName',
      width: 150,
    },
    {
      headerName: type?.levels?.find((level) => level.level === 3)?.systemName,
      filter: true,
      field: 'mainObjectName',
      width: 250,
      hide: (type?.levels?.length ?? 0) < 3 ? true : false,
    },
    {
      headerName: type?.levels?.find((level) => level.level === 4)?.systemName,
      filter: true,
      field: 'childObjectName',
      width: 100,
      hide: (type?.levels?.length ?? 0) < 4 ? true : false,
    },
    {
      headerName: t('measureOverview.planningProjectName'),
      filter: true,
      field: 'planningProjectId',
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: [
          '',
          ...(allProjects && allProjects.length > 0 ? allProjects.map((project) => project.planningProjectId) : []),
        ],
        formatValue: (id: string) => allProjects?.find((project) => project.planningProjectId === id)?.systemName || '',
      },
      filterParams: {
        values: [
          '',
          ...(allProjects && allProjects.length > 0 ? allProjects.map((project) => project.planningProjectId) : []),
        ],
        valueFormatter: (params: any) => {
          return allProjects?.find((project) => project.planningProjectId === params.value)?.systemName || '';
        },
      },
      valueFormatter: (params) => {
        const project = allProjects?.find((p) => p.planningProjectId === params.value);
        return project ? project.systemName : params.value;
      },
      valueSetter: (params) => {
        const selectedProjectId = params.newValue;
        if (selectedProjectId === '') {
          params.data.planningProjectId = undefined; // Set to null or empty string when blank is selected
        } else {
          params.data.planningProjectId = selectedProjectId; // Set the selected ID
        }
        return true;
      },
    },
    {
      headerName: t('measureOverview.systemDescription'),
      field: 'systemDescription',
      sortable: false,
      editable: true,
    },
    {
      headerName: t('priorityId'),
      field: 'planningPriorityId',
      filter: true,
      editable: true,
      width: 130,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: [PlanningPriority.High, PlanningPriority.Medium, PlanningPriority.Low],
        cellRenderer: renderTranslatedValue,
      },
      filterParams: {
        values: [PlanningPriority.High, PlanningPriority.Medium, PlanningPriority.Low],
        cellRenderer: renderTranslatedValue,
        suppressMiniFilter: true,
      },
      cellRenderer: renderTranslatedValue,
    },
    {
      headerName: t('consequenceDegree'),
      field: 'consequenceDegree',
      editable: true,
      filter: true,
      filterParams: {
        suppressMiniFilter: true,
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [0, 1, 2, 3],
      },
    },
    {
      headerName: t('measureOverview.planningMeasureMainCategoryId'),
      field: 'planningMeasureMainCategoryId',
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: Object.values(PlanningMeasureMainCategory),
        cellRenderer: renderTranslatedValue,
      },
      filter: true,
      filterParams: {
        values: Object.values(PlanningMeasureMainCategory),
        cellRenderer: renderTranslatedValue,
        suppressMiniFilter: true,
      },
      cellRenderer: renderTranslatedValue,
    },
    {
      headerName: t('startYear'),
      filter: 'agNumberColumnFilter',
      field: 'startYear',
      width: 120,
      ...(selectedProjectIdToFilterOn != null && { sort: 'asc' }),
    },
    {
      headerName: t('endYear'),
      filter: 'agNumberColumnFilter',
      field: 'endYear',
      width: 120,
    },
    {
      headerName: t('totalEstimateCost'),
      filter: 'agNumberColumnFilter',
      field: 'totalEstimateCost',
      width: 185,
      cellRenderer: (params: CustomCellRendererProps) => formatNumber(params.value),
    },
    {
      headerName: t('totalCalculatedCost'),
      filter: 'agNumberColumnFilter',
      field: 'totalCalculatedCost',
      width: 185,
      cellRenderer: (params: CustomCellRendererProps) => formatNumber(params.value),
    },
    {
      headerName: t('buttons.copy'),
      minWidth: 75,
      maxWidth: 75,
      cellClass: 'd-flex',
      cellRenderer: (params: CustomCellRendererProps) => (
        <ActionIcon
          variant="subtle"
          color="grey"
          aria-label="copy"
          size={'sm'}
          onClick={() => {
            setMeasureToCopy(params.data.planningMeasureId);
            setShowCopyModal(true);
          }}
        >
          <MdContentCopy />
        </ActionIcon>
      ),
      editable: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: t('buttons.delete'),
      minWidth: 75,
      maxWidth: 75,
      cellClass: 'd-flex',
      cellRenderer: (params: CustomCellRendererProps) => (
        <ActionIcon
          variant="subtle"
          color="grey"
          aria-label="delete"
          size={'sm'}
          onClick={() => {
            setMeasureToDelete(params.data.planningMeasureId);
            setShowDeleteModal(true);
          }}
        >
          <MdDelete />
        </ActionIcon>
      ),
      editable: false,
      sortable: false,
      filter: false,
    },
  ];

  const defaultColDef = {
    filter: 'agTextColumnFilter',
    suppressHeaderMenuButton: true,
  };

  const onCostCellValueChanged = (event: CellValueChangedEvent, planningMeasureId: string) => {
    const year = parseInt(event.colDef.field!, 10);
    const costType = event.data.costType;
    const planningMeasure = planningMeasures?.find((measure: any) => measure.planningMeasureId === planningMeasureId);

    const planningMeasureCost = planningMeasure?.planningMeasureCosts?.find((cost) => cost.startYear === year);

    const planningMeasureCostId = planningMeasureCost?.planningMeasureCostId;
    const request: UpdatePlanningMeasureCostRequest[] = [
      {
        planningMeasureCostId: planningMeasureCostId,
        planningMeasureId: planningMeasureId,
        startYear: year,
        estimateCost: costType === t('estimatedCost') ? event.newValue : planningMeasureCost?.estimateCost,
        calculatedCost: costType === t('calculatedCost') ? event.newValue : planningMeasureCost?.calculatedCost,
      },
    ];
    updateCost({ planningMeasureId: planningMeasureId, request: request });
  };

  const DetailCellRenderer = ({ data }: CustomCellRendererProps) => {
    const planningCosts = data.planningMeasureCosts;
    const columnYears: number[] = planningCosts?.map((cost: PlanningCost) => cost.startYear);

    const addColumn = () => {
      const request: UpdatePlanningMeasureCostRequest = {
        planningMeasureId: data.planningMeasureId,
        startYear: columnYears[columnYears.length - 1] + 1,
        estimateCost: 0,
        calculatedCost: null,
      };
      addCost({ planningMeasureId: data.planningMeasureId, request: request });
    };

    const removeColumn = () => {
      removeCost({
        planningMeasureCostId: data.planningMeasureCosts[data.planningMeasureCosts.length - 1].planningMeasureCostId,
      });
    };

    const columnDefsCosts: ColDef<any>[] = useMemo(
      () => [
        {
          headerName: 'KostnadsId',
          field: 'planningMeasureCostId',
          hide: true,
        },
        {
          headerName: t('costType'),
          field: 'costType',
          sortable: false,
          cellStyle: { fontWeight: 500 },
          width: 150,
        }, // Static column for the row labels (cost type)
        ...columnYears.map((year) => ({
          headerName: year.toString(),
          field: year.toString(), // Each year becomes a column
          editable: true,
          width: 150,
          sortable: false,
          minWidth: 100,
          cellRenderer: (params: CustomCellRendererProps) => formatNumber(params.value),
        })),
      ],
      [],
    );

    const defaultCostColDef = {
      suppressMovable: true,
      suppressHeaderMenuButton: true,
    };

    const rowData = [
      {
        costType: t('estimatedCost'),
        ...planningCosts.reduce((acc: Record<number, number>, cost: PlanningCost) => {
          acc[cost.startYear] = cost.estimateCost;
          return acc;
        }, {} as any),
      },
      {
        costType: t('calculatedCost'),
        ...planningCosts.reduce((acc: Record<string, number>, cost: PlanningCost) => {
          acc[cost.startYear] = cost.calculatedCost;
          return acc;
        }, {} as any),
      },
    ];

    return (
      <div className="bg-white pt-2 pb-3">
        {planningCosts?.length > 0 && (
          <div className="d-flex">
            <div style={{ width: `${100}px` }}></div> {/* Dynamic width follows first column */}
            <div className="mt-0" style={{ width: '80%' }}>
              <div className="d-flex mb-2 align-items-center">
                <h5 className="mb-0 me-2">{t('costs')}</h5>
                <Button size="xs" mr={'.5em'} onClick={() => addColumn()}>
                  {t('buttons.addCost')}
                </Button>
                <Button variant="secondary" size="xs" onClick={() => removeColumn()} disabled={columnYears.length < 2}>
                  {t('buttons.removeCost')}
                </Button>
              </div>
              <div style={{ width: `${200 + planningCosts?.length * 150}px`, maxWidth: '100%' }}>
                <AgGridReact
                  rowHeight={35}
                  domLayout={'autoHeight'}
                  columnDefs={columnDefsCosts}
                  rowData={rowData}
                  defaultColDef={defaultCostColDef}
                  onCellValueChanged={(e) => onCostCellValueChanged(e, data.planningMeasureId)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <PageTitle title="Tiltak- og vedlikeholdsplan" />
      {serviceTypeLoading ? (
        <LoadingSpinner isLoading={serviceTypeLoading} />
      ) : (
        <>
          {serviceTypes && findPlanningServiceType(serviceTypes) ? (
            <div>
              {isError ? (
                <div>Noe gikk galt</div>
              ) : (
                (!isLoading || planningMeasures) && (
                  <>
                    <Flex gap={0}>
                      <div className="d-flex flex-column justify-content-between mb-3">
                        <div
                          style={{
                            width: '400px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            alignContent: 'flex-start',
                            gap: '8px',
                          }}
                        >
                          {' '}
                          <Button size="xs" onClick={() => setShowCreateModal(true)}>
                            {t('buttons.addMeasure')}
                          </Button>
                          <Nav.Link as={Link} to={'/planning/projectoverview'}>
                            <Button size="xs">{t('buttons.projectOverview')}</Button>
                          </Nav.Link>
                          <Input
                            size="sm"
                            placeholder={t('search')}
                            style={{ marginTop: '1px' }}
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          <Button
                            className="me-2"
                            size="xs"
                            variant={planningMeasureFilter === 'ALL' ? 'primary' : 'secondary'}
                            onClick={() => setPlanningMeasureFilter('ALL')}
                          >
                            Alle
                          </Button>
                          <Button
                            className="me-2"
                            size="xs"
                            variant={planningMeasureFilter === 'OPEN' ? 'primary' : 'secondary'}
                            onClick={() => setPlanningMeasureFilter('OPEN')}
                          >
                            Åpne
                          </Button>
                          <Button
                            size="xs"
                            variant={planningMeasureFilter === 'CLOSED' ? 'primary' : 'secondary'}
                            onClick={() => setPlanningMeasureFilter('CLOSED')}
                          >
                            Lukkede
                          </Button>
                        </div>
                      </div>
                      <PlanningMeasuresDashboard planningMeasures={filteredPlanningMeasures ?? []} gridRef={gridRef} />
                    </Flex>
                    <div className="w-100" style={{ fontWeight: 400, height: '800px', overflow: 'auto' }}>
                      <AgGridReact
                        getRowId={(p) => p.data.planningMeasureId}
                        ref={gridRef}
                        rowData={
                          selectedProjectIdToFilterOn != null
                            ? filteredPlanningMeasures?.filter(
                                (measure) => measure.planningProjectId === selectedProjectIdToFilterOn,
                              )
                            : filteredPlanningMeasures
                        }
                        columnDefs={columns}
                        defaultColDef={defaultColDef}
                        rowHeight={35}
                        detailCellRenderer={DetailCellRenderer}
                        onCellValueChanged={onMeasureCellValueChanged}
                        masterDetail={true}
                        detailRowHeight={210}
                        pagination={true}
                        paginationPageSize={50}
                        suppressColumnVirtualisation={true}
                        suppressRowVirtualisation={true}
                        localeText={AG_GRID_LOCALE_NOR}
                        sideBar={sideBar}
                        quickFilterText={quickFilterText}
                        gridOptions={{
                          alwaysShowVerticalScroll: true,
                        }}
                      />
                    </div>
                  </>
                )
              )}
              {showCreateModal && (
                <CreatePlanningMeasureModal
                  show={showCreateModal}
                  onHide={() => setShowCreateModal(false)}
                  workspaceId={workspace?.workspaceId as string}
                  projectId={selectedProjectIdToFilterOn != null ? selectedProjectIdToFilterOn : null}
                />
              )}
              {showCopyModal && (
                <CreatePlanningMeasureModal
                  show={showCopyModal}
                  onHide={() => setShowCopyModal(false)}
                  workspaceId={workspace?.workspaceId as string}
                  planningMeasureId={measureToCopy ?? ''}
                />
              )}
              {showUpdateModal && (
                <UpdatePlanningMeasureModal
                  show={showUpdateModal}
                  onHide={() => setShowUpdateModal(false)}
                  workspaceId={workspace?.workspaceId as string}
                  planningMeasureId={activeMeasure ?? ''}
                />
              )}
              {showDeleteModal && (
                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered={true} size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Slett tiltak{' '}
                      {planningMeasures?.find((measure) => measure.planningMeasureId === measureToDelete)?.systemName}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      {' '}
                      Er du sikker på at du vil slette dette tiltaket? <br></br>Merk: Man kan ikke angre etter man har
                      slettet et tiltak.
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="tertiary"
                      onClick={() => {
                        setShowDeleteModal(false);
                      }}
                    >
                      Avbryt
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        if (measureToDelete != null) {
                          handleDeleteMeasure(measureToDelete);
                        }
                        setShowDeleteModal(false);
                      }}
                    >
                      Slett tiltak
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          ) : (
            <>
              <p>
                En omfattende plan for tiltak og vedlikehold gir retningslinjer som er avgjørende for organisasjonens
                samlede drift. Den tjener som en veiviser for ressurspersoner som skal definere og rangere prioriteter
                blant tiltakene. I tillegg til å være til nytte for interne aktører, gir denne planen også klarhet for
                andre interessenter angående omfanget av tiltaks- og vedlikeholdsplanen.
              </p>
              <p>
                Normalt sett strekker en slik plan seg over et tiårsperspektiv. For å sikre at den forblir tilpasset
                gjeldende krav og endringer i porteføljen, anbefales det å gjennomgå og oppdatere den årlig. Dette
                bidrar til kontinuerlig oversikt over porteføljen og de tilknyttede kostnadene, og sikrer at planen til
                enhver tid er i samsvar med organisasjonens behov.
              </p>
              <p className="fw-bold">Pris: 49 500,- NOK per år</p>
              <Button variant="primary" onClick={() => setShowSubscribeModal(true)}>
                Bestill tiltak- og vedlikeholdsplan
              </Button>
            </>
          )}
        </>
      )}
      <Modal show={showSubscribeModal} onHide={() => setShowSubscribeModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Vil du abonnere på tiltak- og vedlikeholdsplan?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Modulens faste vederlag vil være gjenstand for årlig forskuddsvis fakturering. Betalingsfrist er 30 dager
          etter fakturadato. Kunden må spesifisere og begrunne eventuelle innsigelser til Leverandørens faktura uten
          ugrunnet opphold. Gjør han ikke det legges fakturaen til grunn for oppgjøret. Ved forsinket betaling regnes
          renter etter «lov om rente ved forsinket betaling». Leverandøren har rett til å holde tilbake tilgang til
          programvaren ved forsinket betaling. Dersom forfalt uomtvistet vederlag med tillegg av forsinkelsesrenter ikke
          er betalt innen 30 (tretti) kalenderdager fra forfall, kan Leverandøren sende skriftlig varsel til Kunden om
          at avtalen vil bli hevet dersom oppgjør ikke er skjedd innen 30 (tretti) kalenderdager etter at varselet er
          mottatt. Heving kan ikke skje hvis Kunden gjør opp forfalt vederlag med tillegg av forsinkelsesrenter innen
          fristens utløp.
          <br></br>
          <br></br>
          Leverandøren har eiendomsrett til produktene og innholdet, herunder programvaren, metodikken og
          veiledningsmateriell benyttet i multiMap. Leverandøren har bruksrett på aggregert informasjon av materialet
          som lagres i multiMaps database. Denne bruksretten gjelder kun for bruk av anonymisert informasjon til for
          eksempel nasjonale rapporter. For øvrig har Kunden eiendomsrett til data som de selv har lagt inn i systemet
          og beholder disse dataene etter eventuell oppsigelse av avtalen. Kunden har kun disposisjonsrett til verktøyet
          så lenge Kunden er abonnent på multiMap.
          <br></br>
          <br></br>
          Partene kan si opp avtalen ved skriftlig varsel til den annen part senest 1 måned før dato for fornyelse av
          abonnement. Dato for fornyelse er spesifisert i abonnementsavtalen. Ved oppsigelse løper abonnementet ut
          daværende abonnementsperiode. Det er ikke anledning til å kreve tilbakebetalt deler av abonnementsavgiften ved
          sluttføring av abonnementet før dette. Ved oppsigelse av avtalen kan Kunden, ved forespørsel, få utlevert alle
          sine kartlagte data.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={() => setShowSubscribeModal(false)}>
            Avbryt
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              add({
                request: {
                  organizationId: workspace?.organizationId ?? '',
                  serviceType: 'Planning',
                },
              });
              setShowSubscribeModal(false);
            }}
          >
            Ja, jeg vil abonnere
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
