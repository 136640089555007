import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { createLoginRequest } from 'auth/authConfig';
import { AccessControl } from 'common/api/multimap';
import { LoginFailed } from 'common/components/LoginFailed';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { DesignKitchenSink } from 'features/internal/KitchenSink';
import { Modules } from 'features/user/account-settings/components/modules';
import { Overview } from 'features/user/account-settings/components/overview';
import { ServiceTypes } from 'features/user/account-settings/components/service-types';
import { ActiveUsers } from 'features/user/account-settings/components/users/active-users';
import { InvitedUsers } from 'features/user/account-settings/components/users/invited-users';
import { Workspaces } from 'features/user/account-settings/components/workspaces';
import { DeactiveOrganization } from 'features/user/deactive-organization';
import { Learning } from 'features/user/learning';
import { LearningMapping } from 'features/user/learning/learning-mapping';
import { LearningModule } from 'features/user/learning/learning-module';
import { LearningOverview } from 'features/user/learning/learning-overview';
import { LearningPlanning } from 'features/user/learning/learning-planning';
import { LearningPortfolio } from 'features/user/learning/learning-portfolio';
import { LearningReports } from 'features/user/learning/learning-reports';
import { MaintenancePlan } from 'features/user/maintenance-plan/MaintenancePlan';
import { MaintenancePlanCreate } from 'features/user/maintenance-plan/maintenance-plan-create/MaintenancePlanCreate';
import { MaintenancePlanDetails } from 'features/user/maintenance-plan/maintenance-plan-details/maintenance-plan-details';
import { MaintenancePlanModules } from 'features/user/maintenance-plan/maintenance-plan-modules/MaintenancePlanModules';
import { MaintenancePlanOverview } from 'features/user/maintenance-plan/maintenance-plan-overview/MaintenancePlanOverview';
import { Mapping } from 'features/user/mapping';
import { MappingObjects } from 'features/user/mapping/mapping-objects/MappingObjects';
import { MappingObjectDetail } from 'features/user/mapping/mapping-objects/detail/MappingObjectDetail';
import { MappingOverview } from 'features/user/mapping/mapping-overview/MappingOverview';
import { MappingPeriods } from 'features/user/mapping/mapping-periods/MappingPeriods';
import { PasswordReset } from 'features/user/password-reset';
import { Planning } from 'features/user/planning/Planning';
import { PlanningOverview } from 'features/user/planning/planning-overview/PlanningOverview';
import { PlanningProjectOverview } from 'features/user/planning/planning-project-overview/PlanningProjectOverview';
import { RedeemInvitation } from 'features/user/redeem-invitation/RedeemInvitation';
import { Report } from 'features/user/report';
import { ReportOverview } from 'features/user/report/report-overview/ReportOverview';
import { ReportTypes } from 'features/user/report/report-types/ReportTypes';
import { BasisReport } from 'features/user/report/report-types/detail/BasisReport/BasisReport';
import { MappingDataReport } from 'features/user/report/report-types/detail/MappingDataReport/MappingDataReport';
import { TableReport } from 'features/user/report/report-types/detail/TableReport/TableReport';
import { ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Admin } from '../features/admin';
import { Landing } from '../features/public/landing';
import { SignedOut } from '../features/public/signedOut';
import { AccountSettings } from '../features/user/account-settings';
import { Profile } from '../features/user/account-settings/components/profile';
import { Home } from '../features/user/home';
import { ObjectRegistry } from '../features/user/object-registry';
import { adminRoutes } from './RouteConfig';

const authenticate = (component: ReactNode): ReactNode => {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={createLoginRequest()}
      errorComponent={LoginFailed}
    >
      {component}
    </MsalAuthenticationTemplate>
  );
};

const Router = () => {
  const { hasAccess } = useAuthenticatedUser();
  return (
    <Routes>
      <Route path={'/'} element={<Landing />} />
      <Route path={'/home'} element={authenticate(<Home />)} />
      <Route path={'/object-registry'} element={authenticate(<ObjectRegistry />)} />
      <Route path={'/object-registry/:paramsLevel/:paramsParentId'} element={authenticate(<ObjectRegistry />)} />
      <Route path={'/signed-out'} element={<SignedOut />} />
      <Route path={'/redeem-invitation/:invitationId'} element={authenticate(<RedeemInvitation />)} />
      <Route path={'/password-reset'} element={authenticate(<PasswordReset />)} />
      <Route path={'/deactive-organization'} element={authenticate(<DeactiveOrganization />)} />

      <Route path={'/account-settings'} element={authenticate(<AccountSettings />)}>
        <Route index element={<Overview />} />
        <Route path="profile" element={<Profile />} />
        <Route path="/account-settings" element={<Overview />} />
        <Route path="/account-settings/workspaces" element={<Workspaces />} />
        <Route path="/account-settings/users/active" element={<ActiveUsers />} />
        <Route path="/account-settings/users/invited" element={<InvitedUsers />} />
        <Route path="/account-settings/modules" element={<Modules />} />
        <Route path="/account-settings/service-types" element={<ServiceTypes />} />
      </Route>

      <Route path={'/learning'} element={authenticate(<Learning />)}>
        <Route index element={<LearningOverview />} />
        <Route path="overview" element={<LearningOverview />} />
        <Route path="portfolio" element={<LearningPortfolio />} />
        <Route path="mapping" element={<LearningMapping />} />
        <Route path="reports" element={<LearningReports />} />
        <Route path="planning" element={<LearningPlanning />} />
        <Route path="module/:id" element={<LearningModule />} />
      </Route>

      <Route path={'/mapping'} element={authenticate(<Mapping />)}>
        <Route index element={<MappingOverview />} />
        <Route path={':workspaceModuleId/periods'} element={<MappingPeriods />} />
        <Route path={':workspaceModuleId/periods/:periodId/objects'} element={<MappingObjects />} />
        <Route
          path={':workspaceModuleId/periods/:periodId/objects/:mappingObjectId'}
          element={<MappingObjectDetail />}
        />
      </Route>

      <Route path={'/report'} element={authenticate(<Report />)}>
        <Route index element={<ReportOverview />} />
        <Route path={':workspaceModuleId/report-types'} element={<ReportTypes />} />
        <Route path={':workspaceModuleId/report-types/mapping-data'} element={<MappingDataReport />} />
        <Route path={':workspaceModuleId/report-types/basis-report'} element={<BasisReport />} />
        <Route path={':workspaceModuleId/report-types/table-report'} element={<TableReport />} />
      </Route>

      <Route path={'/maintenance-plan'} element={<MaintenancePlan />}>
        <Route index element={<MaintenancePlanModules />} />
        <Route path={':workspaceModuleId/overview'} element={<MaintenancePlanOverview />} />
        <Route path={':workspaceModuleId/create'} element={<MaintenancePlanCreate />} />
        <Route path={':workspaceModuleId/details'} element={<MaintenancePlanDetails />} />
      </Route>

      <Route path={'/planning'} element={<Planning />}>
        <Route index element={<PlanningOverview />} />
        <Route path={'/planning/projectoverview'} element={<PlanningProjectOverview />} />
      </Route>

      {hasAccess(AccessControl.AdminDashboardView) && (
        <Route path={'/admin'} element={authenticate(<Admin />)}>
          {/* Default route */}
          <Route index element={<Navigate to={'requests'} replace />} />
          {/* All admin routes */}
          {adminRoutes.map((item, index) => (
            <Route
              key={`route-${index}`}
              path={item.path}
              element={hasAccess(item.requiredPermission) ? item.component : null}
            />
          ))}
        </Route>
      )}
      <Route path={'/internal/design'} element={<DesignKitchenSink />} />
    </Routes>
  );
};

export default Router;
