import { useMsal } from '@azure/msal-react';
import {
  ArrowDropDown,
  ContactSupportOutlined,
  SchoolOutlined,
  SearchOutlined,
  SettingsOutlined,
} from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import TranslateIcon from '@mui/icons-material/Translate';
import { ReactComponent as MultimapLogoWhiteSvg } from 'assets/images/multimap-logo-white.svg';
import { AccessControl, Language } from 'common/api/multimap';
import { useAuthenticatedUser } from 'common/contexts/AuthenticatedUserContext';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import { Navbar as BSNavbar, Button, Container, Form, InputGroup, Modal, Nav, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useNavigate } from 'react-router-dom';

import { createLoginRequest } from '../../../auth/authConfig';
import useLocalization from '../../hooks/useLocalization';
import { ContactModal } from '../Contact/ContactModal';
import useGetWorkspacesOnUserId from './hooks/useGetWorkspacesOnUserId';
import styles from './navbar.module.scss';

interface Workspace {
  systemName: string;
  workspaceId: string;
}

export const Navbar = () => {
  const { t } = useTranslation();

  const { instance, accounts } = useMsal();
  const { hasAccess, profileInfo } = useAuthenticatedUser();

  const [active, setActive] = useState<boolean>(false);
  const [showNavItems, setShowNavItems] = useState<boolean>(false);
  const [currentLanguage, setLanguage] = useLocalization();

  const [showWorkspacePicker, setShowWorkspacePicker] = useState<boolean>(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [, , data, fetch] = useGetWorkspacesOnUserId(accounts[0] ? accounts[0].localAccountId : '');

  const [workspace, setWorkspace] = useLocalStorage<Workspace | undefined>('workspace', undefined);
  const [isChanged, setIsChanged] = useLocalStorage<boolean>('portfolio-is-changed', false);

  const [filter, setFilter] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    if (accounts.length > 0) {
      setActive(true);
    }
  }, [accounts]);

  const handleLogin = () => {
    setWorkspace(undefined);
    setIsChanged(false);
    navigate('/home');
    instance.loginRedirect(createLoginRequest()).catch((error) => console.log(error));
  };

  const handleLogout = () => {
    setWorkspace(undefined);
    setIsChanged(false);
    instance.logout();
  };

  const checkRolesAndDB = () => {
    const roles = accounts[0]?.idTokenClaims?.roles;
    console.log(roles);
    if (profileInfo && roles) {
      if (profileInfo.isSystemAdministrator && !roles.some((x) => x == 'admin' || x == 'multiAdmin')) {
        handleLogout();
      }

      if (profileInfo.isOrganizationAdministrator && !roles.some((x) => x == 'orgAdmin')) {
        handleLogout();
      }
    }
  };

  useEffect(() => {
    checkRolesAndDB();
  }, [profileInfo]);

  useEffect(() => {
    if (data) {
      if (workspace == undefined && data.length > 0) {
        setShowWorkspacePicker(true);
      }

      if (workspace && !data.some((x) => x.workspaceId == workspace.workspaceId)) {
        setWorkspace(undefined);
      }
    }
  }, [showWorkspacePicker, workspace, data]);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (profileInfo?.isSystemAdministrator || profileInfo?.organization == null || profileInfo?.organization.isActive) {
      setShowNavItems(true);
    }
  }, [profileInfo]);

  const changeLanguage = () => {
    setLanguage(currentLanguage.id === Language.Norwegian ? Language.English : Language.Norwegian);
  };

  return (
    <>
      <BSNavbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container fluid className="px-sm-4 px-lg-5">
          <BSNavbar.Brand>
            <Link to={active ? '/home' : '/'}>
              <MultimapLogoWhiteSvg className="size-xxl" title="Multimap logo" />
            </Link>
          </BSNavbar.Brand>
          <BSNavbar.Toggle aria-controls="responsive-navbar-nav" />
          <BSNavbar.Collapse id="responsive-navbar-nav">
            {!active ? (
              // <Nav className={`justify-content-end flex-grow-1 align-items-end ${styles.navLeft}`}>
              //   <Nav.Link className={styles.link}>{t('about-multimap')}</Nav.Link>
              //   <Nav.Link className={styles.linkSpace}>/</Nav.Link>
              //   <Nav.Link className={styles.link}>{t('products')}</Nav.Link>
              //   <Nav.Link className={styles.linkSpace}>/</Nav.Link>
              //   <Nav.Link className={styles.link}>{t('reference-projects')}</Nav.Link>
              //   <Nav.Link className={styles.linkSpace}>/</Nav.Link>
              //   <Nav.Link className={styles.link}>{t('subscription')}</Nav.Link>
              //   <Nav.Link className={styles.linkSpace}>/</Nav.Link>
              //   <Nav.Link className={styles.link}>{t('contact')}</Nav.Link>
              // </Nav>
              <></>
            ) : (
              <>
                {showNavItems && (
                  <Nav className={`justify-content-end flex-grow-1 align-items-end ${styles.navShowOnMobile}`}>
                    <Nav.Link className={`${styles.link}`}>{t('overview')}</Nav.Link>
                    <Nav.Link className={styles.linkSpace}>/</Nav.Link>
                    <Nav.Link className={`${styles.link}`}>{t('portfolio')}</Nav.Link>
                    <Nav.Link className={styles.linkSpace}>/</Nav.Link>
                    <Nav.Link className={`${styles.link}`}>{t('mapping')}</Nav.Link>
                    <Nav.Link className={styles.linkSpace}>/</Nav.Link>
                    <Nav.Link className={`${styles.link}`}>{t('reports')}</Nav.Link>
                    <Nav.Link className={styles.linkSpace}>/</Nav.Link>
                    <Nav.Link className={`${styles.link}`}>{t('planning')}</Nav.Link>
                  </Nav>
                )}
              </>
            )}
            <Nav className={`justify-content-end flex-grow-1 align-items-end ${styles.navRight}`}>
              {!active ? (
                <div className={styles.upAndDown}>
                  <div className={styles.up}>
                    <Nav.Link className={`${styles.link} ${styles.language}`} onClick={changeLanguage}>
                      <TranslateIcon fontSize="small" className="color-grey" />{' '}
                      <small>{currentLanguage.displayName}</small>
                    </Nav.Link>
                  </div>
                  <div className={styles.down}>
                    <Nav.Link className={styles.link} onClick={handleLogin}>
                      <LoginIcon fontSize="small" className="color-grey" /> {t('login')}
                    </Nav.Link>
                  </div>
                </div>
              ) : (
                <div className={styles.upAndDown}>
                  <div className={styles.up}>
                    <Nav.Link className={`${styles.link} ${styles.language}`} onClick={changeLanguage}>
                      <TranslateIcon fontSize="small" className="color-grey" /> <small>{t('language')}</small>
                    </Nav.Link>
                  </div>
                  <div className={styles.down}>
                    {hasAccess(AccessControl.AdminDashboardView) && (
                      <Nav.Link as={Link} className={styles.link} to={'/admin'}>
                        <AccountCircleIcon fontSize="small" className="color-grey" /> {t('loginAdmin')}
                      </Nav.Link>
                    )}
                    {showNavItems && (
                      <>
                        <Nav.Link
                          as={Link}
                          className={`${styles.link}`}
                          to={window.location.pathname}
                          onClick={() => setShowWorkspacePicker(true)}
                        >
                          {workspace ? workspace.systemName : 'Ingen arbeidsområde valgt'}
                          <ArrowDropDown fontSize="small" className="color-grey ms-1" />
                        </Nav.Link>
                        <Nav.Link as={Link} className={styles.link} to={'/learning'}>
                          <SchoolOutlined fontSize="small" className="color-grey" /> {t('learning')}
                        </Nav.Link>
                        <Nav.Link as={Link} className={styles.link} to={'/account-settings'}>
                          <SettingsOutlined fontSize="small" className="color-grey" /> {t('account-settings')}
                        </Nav.Link>
                      </>
                    )}
                    <Nav.Link className={styles.link} onClick={() => setShowContactModal(true)}>
                      <ContactSupportOutlined fontSize="small" className="color-grey" /> {t('contact')}
                    </Nav.Link>
                    <Nav.Link className={styles.link} onClick={handleLogout}>
                      <LogoutIcon fontSize="small" className="color-grey" /> {t('logout')}
                    </Nav.Link>
                  </div>
                </div>
              )}
            </Nav>
          </BSNavbar.Collapse>
        </Container>
      </BSNavbar>

      {active && !matchPath('/admin/*', location.pathname) && workspace && (
        <BSNavbar className={styles.navStyleBottom}>
          <Container fluid className={'px-sm-4 px-lg-5'}>
            <Nav className={`justify-content-start flex-grow-1 align-items-end`}>
              <Nav.Link
                className={`${styles.link} ${styles.linkBottom}`}
                style={{ paddingLeft: 0 }}
                as={Link}
                to="/home"
              >
                {t('overview')}
              </Nav.Link>
              <Nav.Link className={styles.linkSpace} disabled>
                /
              </Nav.Link>
              <Nav.Link className={`${styles.link} ${styles.linkBottom}`} as={Link} to="/object-registry">
                {t('portfolio')}
              </Nav.Link>
              <Nav.Link className={styles.linkSpace} disabled>
                /
              </Nav.Link>
              <Nav.Link className={`${styles.link} ${styles.linkBottom}`} as={Link} to="/mapping">
                {t('mapping')}
              </Nav.Link>
              <Nav.Link className={styles.linkSpace} disabled>
                /
              </Nav.Link>
              <Nav.Link className={`${styles.link} ${styles.linkBottom}`} as={Link} to={'/report'}>
                {t('reports')}
              </Nav.Link>
              {hasAccess(AccessControl.AdminDashboardView) && (
                <>
                  <Nav.Link className={styles.linkSpace} disabled>
                    /
                  </Nav.Link>
                  <Nav.Link className={`${styles.link} ${styles.linkBottom}`} as={Link} to={'/maintenance-plan'}>
                    {t('planning')} depricated
                  </Nav.Link>
                </>
              )}
              <Nav.Link className={styles.linkSpace} disabled>
                /
              </Nav.Link>
              <Nav.Link className={`${styles.link} ${styles.linkBottom}`} as={Link} to={'/planning'}>
                {t('planning')}
              </Nav.Link>
            </Nav>
          </Container>
        </BSNavbar>
      )}
      <Modal show={showWorkspacePicker} size="lg" onHide={() => setShowWorkspacePicker(false)} centered>
        <Modal.Header closeButton>Velg arbeidsområde</Modal.Header>
        <Modal.Body>
          <InputGroup>
            <InputGroup.Text>
              <SearchOutlined fontSize="small" />
            </InputGroup.Text>
            <Form.Control
              type="text"
              id="filter"
              placeholder="Søk på arbeidsområde"
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
          <div className="overflow-scroll mt-3" style={{ maxHeight: '500px' }}>
            <Table hover responsive>
              <thead>
                <tr>
                  <th scope="col">Arbeidsområde</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data
                    .filter(
                      (x) =>
                        x.systemName.toLowerCase().includes(filter.toLowerCase()) ||
                        x.organizationName?.toLowerCase().includes(filter.toLowerCase()),
                    )
                    .sort((a, b) => {
                      if (a.systemName < b.systemName) {
                        return -1;
                      }
                      if (a.systemName > b.systemName) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((row, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {row.systemName} ({row.organizationName})
                          </td>
                          <td className="d-flex justify-content-end">
                            <Button
                              variant="tertiary"
                              size="sm"
                              onClick={() => {
                                if (isChanged) {
                                  if (
                                    confirm(
                                      'Du er i ferd med å forlate arbeidsområde med ulagrede endringer. Er du sikker?',
                                    ) == false
                                  ) {
                                    return;
                                  }
                                }
                                setWorkspace(row);
                                setIsChanged(false);
                                setShowWorkspacePicker(false);
                                navigate('/home');
                                location.reload();
                              }}
                            >
                              Velg arbeidsområde
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={() => setShowWorkspacePicker(false)}>
            Lukk
          </Button>
        </Modal.Footer>
      </Modal>
      <ContactModal show={showContactModal} onHide={() => setShowContactModal(false)} theme="Generelt" />
    </>
  );
};
