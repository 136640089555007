import { Button, Flex, Input } from '@mantine/core';
import '@mantine/core/styles/Tooltip.css';
import { useDebouncedState } from '@mantine/hooks';
import { InfoOutlined } from '@mui/icons-material';
import { CreatePlanningMeasureRequest, PlanningMeasureMainCategory, PlanningPriority } from 'common/api/multimap';
import { ConditionDegreeIcon } from 'common/components/ConditionDegreeIcon/ConditionDegreeIcon';
import { arrayRange } from 'common/helpers/array.helpers';
import { Guid } from 'common/types/guid.type';
import useGetObjectTypeDetail from 'features/admin/object-types/hooks/useGetObjectTypeDetail';
import useGetWorkspaceDetail from 'features/admin/workspaces/hooks/useGetWorkspaceDetail';
import { useEffect, useState } from 'react';
import { Card, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import useAddPlanningMeasure from '../hooks/useAddPlanningMeasure';
import useGetPlanningAvailableParameters from '../hooks/useGetPlanningAvailableParameters';
import useGetSinglePlanningMeasure from '../hooks/useGetSinglePlanningMeasure';

export interface IProps {
  show: boolean;
  onHide: () => void;
  workspaceId: string;
  planningMeasureId?: string; // Optional for copying a measure
  projectId?: string | null; // Optional for creating a measure when filtered on a project
}

export const CreatePlanningMeasureModal: React.FC<IProps> = ({
  show,
  onHide,
  workspaceId,
  planningMeasureId,
  projectId,
}) => {
  const [showModal, setShowModal] = useState<boolean>(show);
  const [, , , createdPlan, add] = useAddPlanningMeasure();
  const emptyGuid = '00000000-0000-0000-0000-000000000000';

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const [planningMeasureIsLoading, planningMeasureIsError, planningMeasure, fetchSinglePlanningMeasure] =
    useGetSinglePlanningMeasure(planningMeasureId ?? '');

  const [selectedParentId, setSelectedParentId] = useState<string>(emptyGuid);
  const [selectedMainId, setSelectedMainId] = useState<string>(emptyGuid);
  const [selectedChildId, setSelectedChildId] = useState<string>(emptyGuid);
  const [selectedIdForAvailableParameters, setSelectedIdForAvailableParameters] = useState<Guid>(emptyGuid);
  const [selectedChildIds, setSelectedChildIds] = useState<string[]>([]);
  const [selectedParameterIds, setSelectedParameterIds] = useState<string[]>([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [initialValues, setInitialValues] = useState<CreatePlanningMeasureRequest>();

  const { t } = useTranslation('planning');

  const [isLoading, isError, workspaceDetail] = useGetWorkspaceDetail(workspaceId ?? '');
  const [availableParametersIsLoading, availableParametersIsError, availableParameters, fetch] =
    useGetPlanningAvailableParameters(selectedIdForAvailableParameters);
  const [typeIsLoading, , type] = useGetObjectTypeDetail(
    workspaceDetail ? workspaceDetail.objectType.objectTypeId : '',
  );

  const parentObjectOptions = workspaceDetail?.levels?.filter((level) => level.level === 2);
  let mainObjectOptions = workspaceDetail?.levels?.filter((level) => level.level === 3);
  let childObjectOptions = workspaceDetail?.levels?.filter((level) => level.level === 4);

  if (selectedParentId !== emptyGuid) {
    mainObjectOptions = mainObjectOptions?.filter((opt) => opt.parentId === selectedParentId);
  }

  if (selectedMainId !== emptyGuid) {
    childObjectOptions = childObjectOptions?.filter((opt) => opt.parentId === selectedMainId);
  }

  const convertedParentObjectOptions = [
    { value: emptyGuid, label: 'Ingen verdi' },
    ...(parentObjectOptions?.map((x) => ({
      value: x.objectId,
      label: x.systemName,
    })) || []),
  ];

  const convertedMainObjectOptions = [
    { value: emptyGuid, label: 'Ingen verdi' },
    ...(mainObjectOptions?.map((x) => ({
      value: x.objectId,
      label: x.systemName,
    })) || []),
  ];

  const convertedChildObjectOptions = [
    { value: emptyGuid, label: 'Ingen verdi' },
    ...(childObjectOptions?.map((x) => ({
      value: x.objectId,
      label: x.systemName,
    })) || []),
  ];

  const updateParentAndMainId = (mainObjectId: Guid, level: 'parent' | 'main' | 'child') => {
    const handleParentLevel = () => {
      if (selectedMainId !== emptyGuid) setSelectedMainId(emptyGuid);
      setSelectedChildId(emptyGuid);
      setSelectedParentId(mainObjectId);
      setSelectedIdForAvailableParameters(mainObjectId);
    };

    const handleMainLevel = () => {
      setSelectedChildId(emptyGuid);
      if (mainObjectId === emptyGuid) {
        setSelectedIdForAvailableParameters(selectedParentId);
      } else {
        setSelectedIdForAvailableParameters(mainObjectId);
        setSelectedParentId(mainObjectOptions?.find((x) => x.objectId === mainObjectId)?.parentId || emptyGuid);
      }
      setSelectedMainId(mainObjectId);
    };

    const handleChildLevel = () => {
      if (mainObjectId === emptyGuid) {
        setSelectedIdForAvailableParameters(selectedMainId === emptyGuid ? selectedParentId : selectedMainId);
      } else {
        setSelectedIdForAvailableParameters(mainObjectId);
      }
      setSelectedChildId(mainObjectId);
    };

    switch (level) {
      case 'parent':
        handleParentLevel();
        break;
      case 'main':
        handleMainLevel();
        break;
      case 'child':
        handleChildLevel();
        break;
      default:
        console.error('Invalid level provided');
    }
  };

  useEffect(() => {
    fetch();
  }, [selectedIdForAvailableParameters]);

  const handleCardSelection = (cardId: string) => {
    if (selectedParameterIds.includes(cardId)) {
      setSelectedParameterIds(selectedParameterIds.filter((id) => id !== cardId));
    } else {
      setSelectedParameterIds([...selectedParameterIds, cardId]);
    }
  };

  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<CreatePlanningMeasureRequest>();

  useEffect(() => {
    if (createdPlan) {
      onHide();
    }
  }, [createdPlan]);

  useEffect(() => {
    if (planningMeasure) {
      let parentId = null;
      let mainId = null;
      let childId = null;

      const initialObject = workspaceDetail?.levels?.find((level) => level.objectId === planningMeasure?.mainObjectId);

      switch (initialObject?.level) {
        case 2:
          parentId = initialObject.objectId;
          break;
        case 3:
          mainId = initialObject.objectId;
          parentId = initialObject.parentId;
          break;
        case 4:
          childId = initialObject.objectId;
          mainId = workspaceDetail?.levels?.find((level) => level.objectId === initialObject.parentId)?.objectId;
          parentId = workspaceDetail?.levels?.find((level) => level.objectId === initialObject.parentId)?.parentId;
          break;
        default:
          // Optional: Handle cases where initialObject is undefined or level doesn't match 2, 3, or 4
          break;
      }
      setSelectedParentId(parentId || '');
      setSelectedMainId(mainId || '');
      setSelectedChildId(childId || '');
      setSelectedIdForAvailableParameters(planningMeasure?.mainObjectId || emptyGuid);
      setSelectedParameterIds(planningMeasure?.planningMeasureParameters?.map((x) => x.mappingParameterId) || []);
    }
  }, [planningMeasure]);

  useEffect(() => {
    if (planningMeasure) {
      reset({
        systemName: planningMeasure.systemName,
        systemDescription: planningMeasure.systemDescription,
        planningMeasureNumber: planningMeasure.planningMeasureNumber,
        planningPriorityId: planningMeasure.planningPriorityId,
        planningMeasureMainCategoryId: planningMeasure.planningMeasureMainCategoryId,
        consequenceDegree: planningMeasure.consequenceDegree,
        mainObjectId: planningMeasure.mainObjectId,
        startYear: planningMeasure.startYear,
        endYear: planningMeasure.endYear,
        totalEstimateCost: planningMeasure.totalEstimateCost,
        totalCalculatedCost: planningMeasure.totalCalculatedCost === 0 ? NaN : planningMeasure.totalCalculatedCost,
      });
      setInitialValues({
        systemName: planningMeasure.systemName,
        systemDescription: planningMeasure.systemDescription,
        planningMeasureNumber: planningMeasure.planningMeasureNumber,
        planningPriorityId: planningMeasure.planningPriorityId,
        planningMeasureMainCategoryId: planningMeasure.planningMeasureMainCategoryId,
        consequenceDegree: planningMeasure.consequenceDegree,
        mainObjectId: planningMeasure.mainObjectId,
        startYear: planningMeasure.startYear,
        endYear: planningMeasure.endYear,
        totalEstimateCost: planningMeasure.totalEstimateCost,
        totalCalculatedCost: planningMeasure.totalCalculatedCost === 0 ? NaN : planningMeasure.totalCalculatedCost,
      });
    } else {
      const defaultInitialValues: CreatePlanningMeasureRequest = {
        systemName: '',
        systemDescription: '',
        planningMeasureNumber: '',
        planningPriorityId: PlanningPriority.Low,
        planningMeasureMainCategoryId: PlanningMeasureMainCategory.Security,
        consequenceDegree: 0,
        mainObjectId: emptyGuid,
        startYear: 2000, // Default to the current year
        endYear: 2000,
        totalEstimateCost: NaN,
        totalCalculatedCost: NaN,
      };

      setInitialValues(defaultInitialValues);
      reset(defaultInitialValues);
    }
  }, [planningMeasure, show]);

  const [quickFilterText, setQuickFilterText] = useDebouncedState('', 400);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFilterText(e.currentTarget.value);
  };

  const filteredParameters = Array.isArray(availableParameters)
    ? availableParameters.filter((param: { mappingParameterName: string }) =>
        param.mappingParameterName.toLowerCase().includes(quickFilterText.toLowerCase()),
      )
    : [];

  const addPlanningMeasure = (d: CreatePlanningMeasureRequest) => {
    const selectedParameterIdsToUpdate = selectedParameterIds.filter((id) =>
      availableParameters?.some((x) => x.mappingParameterId === id),
    );
    let selectedMappingId = emptyGuid;
    selectedMappingId = selectedIdForAvailableParameters;
    d.mainObjectId = selectedMappingId;
    d.planningMeasureChildObjectIds = selectedChildIds;
    d.planningMeasureParameterIds = selectedParameterIdsToUpdate;
    d.planningProjectId = projectId;
    add({ request: d });
    setShowModal(false);
  };

  const hasUnsavedChanges = () => {
    const currentValues = getValues();
    return JSON.stringify(currentValues) !== JSON.stringify(initialValues);
  };

  const handleClose = () => {
    if (hasUnsavedChanges()) {
      setShowConfirmDialog(true);
    } else {
      onHide();
    }
  };

  const confirmClose = () => {
    setShowConfirmDialog(false);
    onHide();
  };

  const cancelClose = () => {
    setShowConfirmDialog(false);
  };

  const checkErrors = () => {
    if (isError || availableParametersIsError) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <h5 className="mb-0">{t('buttons.addMeasure')}</h5>
        </Modal.Header>
        <Form onSubmit={handleSubmit((d) => addPlanningMeasure(d))}>
          <Modal.Body>
            <>
              <Row className="border-bottom mb-3">
                <Col>
                  <h5 className="mb-3 d-inline-block">{t('measureOverview.planDetails')}</h5>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.planDetails')}</Tooltip>}
                  >
                    <InfoOutlined className="ms-1" fontSize="small" />
                  </OverlayTrigger>
                  <Row className="mb-3">
                    <Col>
                      <Form.Group>
                        <Form.Label>{type?.levels?.find((level) => level.level === 2)?.systemName}</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.location')}</Tooltip>}
                        >
                          <InfoOutlined className="ms-1" fontSize="small" />
                        </OverlayTrigger>
                        {selectedIdForAvailableParameters === emptyGuid && <span className="ps-2 text-danger">*</span>}
                        <Select
                          required
                          options={convertedParentObjectOptions}
                          value={
                            selectedMainId && !selectedParentId
                              ? parentObjectOptions
                                  ?.filter(
                                    (x) =>
                                      x.objectId ===
                                      mainObjectOptions?.find((m) => m.objectId === selectedMainId)?.parentId,
                                  )
                                  ?.map((x) => ({ value: x.objectId, label: x.systemName }))[0]
                              : parentObjectOptions
                                  ?.filter((x) => x.objectId === selectedParentId)
                                  ?.map((x) => ({ value: x.objectId, label: x.systemName }))[0]
                          }
                          onChange={(selectedOption) => {
                            updateParentAndMainId(selectedOption?.value || emptyGuid, 'parent');
                          }}
                        ></Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>{type?.levels?.find((level) => level.level === 3)?.systemName}</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.building')}</Tooltip>}
                        >
                          <InfoOutlined className="ms-1" fontSize="small" />
                        </OverlayTrigger>
                        <Select
                          options={convertedMainObjectOptions}
                          value={
                            selectedMainId !== emptyGuid
                              ? mainObjectOptions
                                  ?.filter((x) => x.objectId === selectedMainId)
                                  ?.map((x) => ({ value: x.objectId, label: x.systemName }))[0]
                              : { value: emptyGuid, label: t('noValue') } // Set blank option when selectedMainId is ""
                          }
                          onChange={(selectedOption) => {
                            updateParentAndMainId(selectedOption?.value || emptyGuid, 'main');
                          }}
                        ></Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Form.Group>
                        <Form.Label>{type?.levels?.find((level) => level.level === 4)?.systemName}</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.floor')}</Tooltip>}
                        >
                          <InfoOutlined className="ms-1" fontSize="small" />
                        </OverlayTrigger>
                        <Select
                          isDisabled={selectedMainId === emptyGuid ? true : false}
                          options={convertedChildObjectOptions}
                          value={
                            selectedChildId !== emptyGuid
                              ? childObjectOptions
                                  ?.filter((x) => x.objectId === selectedChildId)
                                  ?.map((x) => ({ value: x.objectId, label: x.systemName }))[0]
                              : { value: emptyGuid, label: t('noValue') } // Set blank option when selectedMainId is ""
                          }
                          onChange={(selectedOption) => {
                            updateParentAndMainId(selectedOption?.value || emptyGuid, 'child');
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={8}>
                      <Form.Group>
                        <Form.Label>{t('measureOverview.systemName')}</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.measureName')}</Tooltip>}
                        >
                          <InfoOutlined className="ms-1" fontSize="small" />
                        </OverlayTrigger>
                        {errors.systemName && <span className="ps-2 text-danger">*</span>}
                        <Form.Control
                          placeholder={t('measureOverview.planningMeasureNamePlaceholder')}
                          {...register('systemName', { required: true })}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>{t('measureOverview.planningMeasureNumber')}</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.measureNumber')}</Tooltip>}
                        >
                          <InfoOutlined className="ms-1" fontSize="small" />
                        </OverlayTrigger>
                        {errors.planningMeasureNumber && <span className="ps-2 text-danger">*</span>}
                        <Form.Control placeholder="Eksempel: 123" {...register('planningMeasureNumber')}></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>{t('measureOverview.planningMeasureMainCategoryId')}</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.mainCategory')}</Tooltip>}
                        >
                          <InfoOutlined className="ms-1" fontSize="small" />
                        </OverlayTrigger>
                        <Form.Select {...register('planningMeasureMainCategoryId', { required: true })}>
                          <option value={PlanningMeasureMainCategory.Security} selected>
                            {t(PlanningMeasureMainCategory.Security)}
                          </option>
                          <option value={PlanningMeasureMainCategory.Maintenance}>
                            {t(PlanningMeasureMainCategory.Maintenance)}
                          </option>
                          <option value={PlanningMeasureMainCategory.Aesthetic}>
                            {t(PlanningMeasureMainCategory.Aesthetic)}
                          </option>
                          <option value={PlanningMeasureMainCategory.Environment}>
                            {t(PlanningMeasureMainCategory.Environment)}
                          </option>
                          <option value={PlanningMeasureMainCategory.ClimateAdaption}>
                            {t(PlanningMeasureMainCategory.ClimateAdaption)}
                          </option>
                          <option value={PlanningMeasureMainCategory.UniversalDesign}>
                            {t(PlanningMeasureMainCategory.UniversalDesign)}
                          </option>
                          <option value={PlanningMeasureMainCategory.Functionality}>
                            {t(PlanningMeasureMainCategory.Functionality)}
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>{t('consequenceDegree')}</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.consequenceDegree')}</Tooltip>}
                        >
                          <InfoOutlined className="ms-1" fontSize="small" />
                        </OverlayTrigger>
                        <Form.Select {...register('consequenceDegree', { valueAsNumber: true })}>
                          <option value={0} selected>
                            0
                          </option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label>{t('priorityId')}</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.priority')}</Tooltip>}
                        >
                          <InfoOutlined className="ms-1" fontSize="small" />
                        </OverlayTrigger>
                        {errors.planningPriorityId && <span className="ps-2 text-danger">*</span>}
                        <Form.Select {...register('planningPriorityId', { required: true })}>
                          <option value={PlanningPriority.High}>{t(PlanningPriority.High)}</option>
                          <option value={PlanningPriority.Medium}>{t(PlanningPriority.Medium)}</option>
                          <option value={PlanningPriority.Low} selected>
                            {t(PlanningPriority.Low)}
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Form.Group>
                        <Form.Label>{t('measureOverview.systemDescription')}</Form.Label>
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.description')}</Tooltip>}
                        >
                          <InfoOutlined className="ms-1" fontSize="small" />
                        </OverlayTrigger>
                        <Form.Control
                          placeholder={t('descriptionPlaceholder')}
                          as="textarea"
                          {...register('systemDescription')}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {checkErrors() && (
                    <Row>
                      <Col>
                        <p className="text-danger">Ikke alle felter er fylt ut, feltene er markert med*</p>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col>
                  <Flex justify="space-between" align="center" wrap="wrap" mb="sm" pr="sm" gap={'6px'}>
                    <div className="d-flex align-items-center">
                      <h5 style={{ marginBottom: '0' }}>{t('measureOverview.currentStatusAndParameters')}</h5>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.parameters')}</Tooltip>}
                      >
                        <InfoOutlined className="ms-1" fontSize="small" />
                      </OverlayTrigger>
                    </div>
                    <Input size="sm" placeholder={t('search')} w={'150px'} onChange={handleChange} />
                  </Flex>
                  <Row style={{ height: '500px', overflow: 'scroll' }}>
                    <Col xs={12}>
                      <>
                        {availableParameters &&
                          filteredParameters.map((cd: any, index: any) => (
                            <Card className="p-3 mb-3" key={index}>
                              <Row>
                                <Col className="d-flex align-items-center">
                                  <Form.Check
                                    value={cd.mappingParameterId}
                                    label={cd.mappingParameterName}
                                    checked={selectedParameterIds.includes(cd.mappingParameterId)}
                                    onChange={() => handleCardSelection(cd.mappingParameterId)}
                                  />
                                </Col>
                                {
                                  <Col className="d-flex justify-content-end align-items-center">
                                    <small className="text-muted me-2" style={{ fontSize: '0.8rem' }}>
                                      {cd.mappingSetName}
                                    </small>
                                    <div className="d-flex align-items-center">
                                      <ConditionDegreeIcon conditionDegree={cd.currentParameterValue!} />
                                      <small className="ms-1">
                                        {cd.currentParameterValue! !== 99
                                          ? cd.currentParameterValue?.toFixed(1)
                                          : 'N/A'}{' '}
                                        {cd.previousParameterValue! != null
                                          ? `(${cd.previousParameterValue?.toFixed(1)})`
                                          : ''}
                                      </small>
                                    </div>
                                  </Col>
                                }
                              </Row>
                            </Card>
                          ))}
                      </>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <h5 className="d-inline-block">{t('measureOverview.startAndCost')}</h5>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.startAndCost')}</Tooltip>}
                  >
                    <InfoOutlined className="ms-1" fontSize="small" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('plannedStartYear')}</Form.Label>
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.startYear')}</Tooltip>}
                    >
                      <InfoOutlined className="ms-1" fontSize="small" />
                    </OverlayTrigger>
                    <Form.Select {...register(`startYear`, { valueAsNumber: true })}>
                      {arrayRange(2000, 2100, 1).map((year, yearIndex) => (
                        <option key={yearIndex} value={year}>
                          {year}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('plannedEndYear')}</Form.Label>
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.endYear')}</Tooltip>}
                    >
                      <InfoOutlined className="ms-1" fontSize="small" />
                    </OverlayTrigger>
                    <Form.Select {...register(`endYear`, { valueAsNumber: true })}>
                      {arrayRange(watch('startYear') ?? 2000, 2100, 1).map((year, yearIndex) => (
                        <option key={yearIndex} value={year}>
                          {year}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('totalEstimateCost')}</Form.Label>
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.totalEstimateCost')}</Tooltip>}
                    >
                      <InfoOutlined className="ms-1" fontSize="small" />
                    </OverlayTrigger>
                    {errors.totalEstimateCost && <span className="ps-2 text-danger">*</span>}
                    <Form.Control
                      type="number"
                      {...register(`totalEstimateCost`, {
                        valueAsNumber: true,
                        required: true,
                      })}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('totalCalculatedCost')}</Form.Label>
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="tooltip-parent-object">{t('tooltips.totalCalculatedCost')}</Tooltip>}
                    >
                      <InfoOutlined className="ms-1" fontSize="small" />
                    </OverlayTrigger>
                    <Form.Control
                      type="number"
                      {...register(`totalCalculatedCost`, {
                        valueAsNumber: true,
                      })}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              {t('buttons.cancel')}
            </Button>
            <Button type="submit">{t('buttons.create')}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {showConfirmDialog && (
        <Modal show={true} onHide={cancelClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('measureOverview.unsavedChangesTitle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('measureOverview.unsavedChangesText')}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelClose}>
              {t('buttons.cancel')}
            </Button>
            <Button variant="danger" onClick={confirmClose}>
              {t('buttons.discardChanges')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
