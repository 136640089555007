import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router';

import { ProjectProvider } from './components/ProjectContext';

export const Planning = () => {
  useEffect(() => {
    document.title = 'Vedlikeholdsplan';
  }, []);
  return (
    <ProjectProvider>
      <Container fluid className="px-sm-4 px-lg-5 mt-4">
        <Outlet />
      </Container>
    </ProjectProvider>
  );
};
