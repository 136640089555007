import { useMsal } from '@azure/msal-react';
import {
  AssignmentTurnedInOutlined,
  Close,
  CloseOutlined,
  CommentOutlined,
  ContentPasteOutlined,
  DateRangeOutlined,
  InfoOutlined,
  RemoveCircleOutline,
  SaveOutlined,
  UndoOutlined,
} from '@mui/icons-material';
import {
  MappingModel,
  MappingObjectGroupResponse,
  MappingParameterConditionDegreeValueResponse,
  MappingState,
} from 'common/api/multimap';
import { Expander, useExpander } from 'common/components/Expander/Expander';
import { Pill } from 'common/components/Pill/Pill';
import { PageTitle } from 'common/components/page-title/PageTitle';
import { asDateTime } from 'common/helpers/dateHelpers';
import { LoadingSpinner } from 'features/admin/components/loading-spinner/LoadingSpinner';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, ProgressBar, Row, Stack } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import useAddMappingObjectState from '../../hooks/useAddMappingObjectState';
import useGetMappingObjectDetail from '../../hooks/useGetMappingObjectDetail';
import useGetMappingPeriod from '../../hooks/useGetMappingPeriod';
import useGetModuleConditionDescriptions from '../../hooks/useGetModuleConditionDescriptions';
import { MappingObjectMainCommentModal } from './components/MappingObjectMainCommentModal';
import { MappingObjectPDModal } from './components/MappingObjectPDModal';
import { MappingObjectValueCommentModal } from './components/MappingObjectValueCommentModal';

export const MappingObjectDetail = () => {
  const { workspaceModuleId, periodId, mappingObjectId } = useParams();

  const [isLoading, , data, refetchMapping] = useGetMappingObjectDetail(mappingObjectId ? mappingObjectId : '');
  const [isLoadingPeriod, , dataPeriod] = useGetMappingPeriod(periodId ? periodId : '');
  const [stateLoading, , , stateData, add] = useAddMappingObjectState();

  const [parameterId, setParameterId] = useState<string>('');
  const [descriptionLoading, , descriptionData, fetch] = useGetModuleConditionDescriptions(
    data ? data.moduleId : '',
    parameterId,
  );

  const [expandedDetailIds, toggleDetailId, isDetailExpanded] = useExpander<string>();

  const [showPDModal, setShowPDModal] = useState<boolean>(false);

  const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
  const [mappingObjectValue, setMappingObjectValue] = useState<
    MappingParameterConditionDegreeValueResponse | undefined
  >();
  const [mappingObjectStringValue, setMappingObjectStringValue] = useState<string>('');

  const [showMainCommentModal, setShowMainCommentModal] = useState<boolean>(false);

  const { control, register, getValues, handleSubmit, setValue, reset, watch, formState } = useForm();

  const [dirty, setDirty] = useState<boolean>(false);

  const { instance, accounts } = useMsal();
  useEffect(() => {
    if (data) {
      reset({
        mappingObjectId: data.mappingObjectId,
        status: data.status,
        comment: data.comment,
        valueGroups: data.valueGroups,
      });
    }
  }, [data, reset]);

  useEffect(() => {
    if (parameterId) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterId]);

  const navigate = useNavigate();

  const fillMultiple = (headerTG: number) => {
    if (data) {
      getValues('valueGroups').map((x: MappingObjectGroupResponse, groupIndex: number) => {
        x.cdValues?.map((i, valueIndex) => {
          setValue(`valueGroups[${groupIndex}].cdValues.${valueIndex}.value`, headerTG.toString());
          i.childValues?.map((c, childIndex) => {
            setValue(
              `valueGroups[${groupIndex}].cdValues[${valueIndex}].childValues.${childIndex}.value`,
              headerTG.toString(),
            );
          });
        });
      });
    }
  };

  const emptyMultiple = () => {
    if (data) {
      getValues('valueGroups').map((x: MappingObjectGroupResponse, groupIndex: number) => {
        x.cdValues?.map((i, valueIndex) => {
          setValue(`valueGroups[${groupIndex}].cdValues.${valueIndex}.value`, undefined);
          i.childValues?.map((c, childIndex) => {
            setValue(`valueGroups[${groupIndex}].cdValues[${valueIndex}].childValues.${childIndex}.value`, undefined);
          });
        });
      });
    }
  };

  const createNumbers = (start: number, end: number, row: number) => {
    const elements: JSX.Element[] = [];
    for (let i = start; i <= end; i++) {
      elements.push(
        <span
          role={'button'}
          tabIndex={i}
          className={`fw-bold ps-1 pe-auto`}
          onClick={() => {
            fillMultiple(i);
          }}
          onKeyDown={() => {
            fillMultiple(i);
          }}
        >
          {i}
        </span>,
      );
    }
    return elements;
  };

  const addMappingObjectState = (d: FieldValues, state: MappingState) => {
    if (data) {
      d.status = state;

      d.userId = accounts[0].localAccountId;

      add({ id: data.mappingObjectId, request: d });

      if (state == MappingState.Closed) {
        navigate(`/mapping/${workspaceModuleId}/periods/${periodId}/objects`);
      } else {
        refetchMapping();
        setDirty(false);
      }
    }
  };

  const setMultipleValues = (
    i: number,
    index: number,
    value: MappingParameterConditionDegreeValueResponse,
    currentTargetValue: string,
  ) => {
    if (value.childValues) {
      value.childValues.map((row, childIndex) => {
        setValue(`valueGroups[${i}].cdValues[${index}].childValues.${childIndex}.value`, currentTargetValue);
      });
    }
  };

  const checkValues = (i: number, index: number, value: MappingParameterConditionDegreeValueResponse) => {
    let background = false;
    if (value.childValues) {
      value.childValues.map((row, childIndex) => {
        const childValue: string = getValues(`valueGroups[${i}].cdValues[${index}].childValues.${childIndex}.value`);

        if (childValue != value.value) {
          background = true;
        }
      });
    }

    return background;
  };

  const refArray = useRef<(HTMLParagraphElement | null)[]>([]);
  const mainDivRef = useRef<HTMLDivElement | null>(null);
  const topRef = useRef<number>(0);

  const [scrolledY, setScrolledY] = useState(0);

  const handleScroll = () => {
    setScrolledY(document?.getElementById('main-div')?.scrollTop as number);
    let topElement = 0;
    let minTop = Infinity;
    refArray?.current?.forEach((item, idx) => {
      const rect = item?.getBoundingClientRect();
      if (rect) {
        if ((rect?.top >= 0 && rect?.top <= minTop) || (rect?.top >= 0 && rect?.top <= minTop + 65)) {
          minTop = rect.top;
          topElement = idx;
        }
      }
    });
    topRef.current = topElement || 0;
  };

  useEffect(() => {
    document?.getElementById('main-div')?.addEventListener('scroll', handleScroll);
    return () => {
      document?.getElementById('main-div')?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={mainDivRef}>
      <LoadingSpinner isLoading={isLoading || isLoadingPeriod} />
      {dataPeriod && (
        <div>
          <PageTitle
            title={data?.objectParentName}
            backTo={`/mapping/${workspaceModuleId}/periods/${periodId}/objects`}
          >
            <Stack gap={3} direction="horizontal" className="mb-3">
              <Button
                variant="tertiary"
                onClick={() => {
                  reset();
                  setDirty(false);
                }}
              >
                Angre endringer <UndoOutlined />
              </Button>
              <Button
                variant="tertiary"
                onClick={handleSubmit((d) => addMappingObjectState(d, MappingState.InProgress))}
                disabled={!dirty}
              >
                Lagre utkast <SaveOutlined />
              </Button>
              <Button variant="secondary" onClick={handleSubmit((d) => addMappingObjectState(d, MappingState.Closed))}>
                Fullføre kartlegging av objekt <AssignmentTurnedInOutlined />
              </Button>
            </Stack>
          </PageTitle>
          {data && (
            <>
              <Row>
                <Col>
                  <Card className="mb-3">
                    <div className="p-3">
                      <Stack direction="horizontal" className="">
                        <h3>{data.objectName}</h3>
                      </Stack>
                      <Stack direction="horizontal" className="mb-5">
                        <Pill variant="primary" className="me-2">
                          Bruttoareal: {data.areal} m2
                        </Pill>
                        <Pill variant="primary">Byggeår: {data.year}</Pill>
                      </Stack>
                      <Stack direction="horizontal">
                        <div className="d-flex flex-column me-5">
                          <small className="fw-bold">KARTLAGT AV</small>
                          <span className="lead">
                            {data.mappedByUserName ? data.mappedByUserName : 'Ingen foreløpig'}
                          </span>
                        </div>
                        <div className="d-flex flex-column me-5">
                          <small className="fw-bold">DATO STARTET/ENDRET</small>
                          <span className="lead">
                            <DateRangeOutlined /> {asDateTime(data.lastEdited)}
                          </span>
                        </div>
                        <div className="d-flex flex-column me-5">
                          <small className="fw-bold">KOMMENTAR</small>
                          <span className="lead">
                            <Button
                              variant="link"
                              className="p-0 me-2"
                              size="lg"
                              onClick={() => setShowMainCommentModal(true)}
                            >
                              <CommentOutlined />
                            </Button>
                            {getValues('comment') ? getValues('comment') : 'Ingen kommentar'}
                          </span>
                        </div>
                      </Stack>

                      {dirty && (
                        <p className="text-danger mt-3">
                          Du har gjort endringer på dette byggets kartlegging, husk å lagre!
                        </p>
                      )}
                    </div>
                    <hr></hr>
                    <div className="px-3">
                      {getValues('valueGroups') &&
                        getValues('valueGroups')
                          .sort((a: MappingObjectGroupResponse, b: MappingObjectGroupResponse) => {
                            return a.sortOrder - b.sortOrder;
                          })
                          .map((row: MappingObjectGroupResponse, i: number) => {
                            const offsetTop = refArray.current[i]?.offsetTop;
                            let showCD = false;
                            if (i === topRef.current) {
                              showCD = true;
                            }

                            if (offsetTop != null && offsetTop + 210 < scrolledY) {
                              showCD = true;
                            }

                            return (
                              <>
                                <>
                                  <Row
                                    className="sticky-top bg-white pt-3 pb-2 border-bottom"
                                    ref={(ref: HTMLParagraphElement): void => {
                                      refArray.current[i] = ref;
                                    }}
                                  >
                                    <Col xs={6}>
                                      <div className="d-flex flex-column">
                                        <small className="fw-bold">PARAMETER</small>
                                        <h4>{row.mappingGroupName}</h4>
                                      </div>
                                    </Col>
                                    {showCD && (
                                      <Col xs={6} className="d-flex justify-content-end">
                                        <div className="d-flex flex-column">
                                          <small className="fw-bold">TILSTANDSGRADER</small>
                                          <Stack direction="horizontal" gap={5}>
                                            <span
                                              role={'button'}
                                              tabIndex={i}
                                              className={`fw-bold ps-1pe-auto`}
                                              onClick={() => {
                                                emptyMultiple();
                                                setDirty(true);
                                              }}
                                              onKeyDown={() => {
                                                emptyMultiple();
                                                setDirty(true);
                                              }}
                                            >
                                              <Close />
                                            </span>
                                            {createNumbers(data.mapLowValue, data.mapHighValue, i)}
                                            <span
                                              role={'button'}
                                              tabIndex={i}
                                              className={`fw-bold ps-1pe-auto`}
                                              onClick={() => {
                                                fillMultiple(99);
                                                setDirty(true);
                                              }}
                                              onKeyDown={() => {
                                                fillMultiple(99);
                                                setDirty(true);
                                              }}
                                            >
                                              n/a
                                            </span>
                                            <span className="fw-bold">
                                              <CommentOutlined />
                                            </span>
                                          </Stack>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                  {row.cdValues &&
                                    row.cdValues
                                      .sort((a, b) => {
                                        return a.sortOrder - b.sortOrder;
                                      })
                                      .map((value, index) => {
                                        return (
                                          <>
                                            <Row className={`my-2`}>
                                              <Col
                                                xs={6}
                                                className={`${
                                                  checkValues(i, index, value) ? 'background-blue50' : ''
                                                } d-flex align-items-center py-2 rounded-start`}
                                              >
                                                {value.lowerLevelDeviation &&
                                                  value.childValues &&
                                                  value.childValues.length > 0 &&
                                                  data.mappingModelId == MappingModel.MapToObjectChildren && (
                                                    <Expander
                                                      variant="link"
                                                      ids={expandedDetailIds}
                                                      id={value.mappingParameterValueId}
                                                      onToggle={() => toggleDetailId(value.mappingParameterValueId)}
                                                      className="p-0 me-2"
                                                    />
                                                  )}
                                                <span className="larger">{value.mappingParameterName}</span>
                                                <Button
                                                  variant="link"
                                                  onClick={() => {
                                                    setParameterId(value.mappingParameterId);
                                                    setShowPDModal(true);
                                                  }}
                                                >
                                                  <InfoOutlined />
                                                </Button>
                                              </Col>
                                              <Col
                                                xs={6}
                                                className={`${
                                                  checkValues(i, index, value) ? 'background-blue50' : ''
                                                } d-flex align-items-center py-2 rounded-end d-flex justify-content-end`}
                                              >
                                                <Stack direction="horizontal" gap={5}>
                                                  <>
                                                    <Button
                                                      variant="link"
                                                      className="p-0"
                                                      onClick={() => {
                                                        setValue(
                                                          `valueGroups[${i}].cdValues.${index}.value`,
                                                          undefined,
                                                        );
                                                        setDirty(true);
                                                      }}
                                                    >
                                                      <Close />
                                                    </Button>
                                                    {Array.from(
                                                      Array(data.mapHighValue - data.mapLowValue + 1),
                                                      (e, test) => {
                                                        const thisValue = watch(
                                                          `valueGroups[${i}].cdValues.${index}.value`,
                                                        );
                                                        return (
                                                          <Form.Check
                                                            type="radio"
                                                            value={test}
                                                            checked={thisValue == test}
                                                            onChange={(x) =>
                                                              setMultipleValues(i, index, value, x.currentTarget.value)
                                                            }
                                                            onClick={() => {
                                                              setValue(
                                                                `valueGroups[${i}].cdValues.${index}.value`,
                                                                test,
                                                              );
                                                              setDirty(true);
                                                            }}
                                                          ></Form.Check>
                                                        );
                                                      },
                                                    )}
                                                  </>
                                                  <Form.Check
                                                    type="radio"
                                                    value={99}
                                                    checked={watch(`valueGroups[${i}].cdValues.${index}.value`) == 99}
                                                    onClick={() => {
                                                      setValue(`valueGroups[${i}].cdValues.${index}.value`, 99);
                                                      setMultipleValues(i, index, value, '99');
                                                      setDirty(true);
                                                    }}
                                                  ></Form.Check>
                                                  <Button
                                                    variant="link"
                                                    className="p-0"
                                                    onClick={() => {
                                                      setMappingObjectValue(
                                                        getValues(`valueGroups[${i}].cdValues.${index}`),
                                                      );
                                                      setMappingObjectStringValue(
                                                        `valueGroups[${i}].cdValues.${index}.comment`,
                                                      );
                                                      setShowCommentModal(true);
                                                    }}
                                                  >
                                                    <ContentPasteOutlined />
                                                  </Button>
                                                </Stack>
                                              </Col>
                                            </Row>
                                            {isDetailExpanded(value.mappingParameterValueId) && (
                                              <>
                                                {value.childValues &&
                                                  value.childValues
                                                    .sort((a, b) => {
                                                      if (a.mappingParameterName < b.mappingParameterName) {
                                                        return -1;
                                                      }

                                                      if (a.mappingParameterName > b.mappingParameterName) {
                                                        return 1;
                                                      }

                                                      return 0;
                                                    })
                                                    .map((childRow, childIndex) => {
                                                      return (
                                                        <Row className="my-2 ms-3">
                                                          <Col
                                                            xs={6}
                                                            className="background-blue10 d-flex align-items-center py-2 rounded-start"
                                                          >
                                                            <span className="larger">
                                                              {childRow.mappingParameterName}
                                                            </span>
                                                          </Col>
                                                          <Col
                                                            xs={6}
                                                            className="background-blue10 d-flex justify-content-end py-2 rounded-end"
                                                          >
                                                            <Stack direction="horizontal" gap={5}>
                                                              <>
                                                                {Array.from(
                                                                  Array(data.mapHighValue - data.mapLowValue + 1),
                                                                  (e, test) => {
                                                                    const thisValue = watch(
                                                                      `valueGroups[${i}].cdValues[${index}].childValues.${childIndex}.value`,
                                                                    );

                                                                    return (
                                                                      <Form.Check
                                                                        type="radio"
                                                                        value={test}
                                                                        onChange={() =>
                                                                          setValue(
                                                                            `valueGroups[${i}].cdValues.${index}.value`,
                                                                            null,
                                                                          )
                                                                        }
                                                                        onClick={() => {
                                                                          setValue(
                                                                            `valueGroups[${i}].cdValues.${index}.value`,
                                                                            null,
                                                                          );
                                                                          setValue(
                                                                            `valueGroups[${i}].cdValues[${index}].childValues.${childIndex}.value`,
                                                                            test,
                                                                          );
                                                                          setDirty(true);
                                                                        }}
                                                                        checked={thisValue == test}
                                                                      ></Form.Check>
                                                                    );
                                                                  },
                                                                )}
                                                              </>
                                                              <Form.Check
                                                                type="radio"
                                                                checked={
                                                                  watch(
                                                                    `valueGroups[${i}].cdValues[${index}].childValues.${childIndex}.value`,
                                                                  ) == 99
                                                                }
                                                                onChange={() =>
                                                                  setValue(
                                                                    `valueGroups[${i}].cdValues.${index}.value`,
                                                                    null,
                                                                  )
                                                                }
                                                                onClick={() => {
                                                                  setValue(
                                                                    `valueGroups[${i}].cdValues[${index}].childValues.${childIndex}.value`,
                                                                    99,
                                                                  );
                                                                  setValue(
                                                                    `valueGroups[${i}].cdValues.${index}.value`,
                                                                    null,
                                                                  );
                                                                  setDirty(true);
                                                                }}
                                                                value={99}
                                                              ></Form.Check>
                                                              <Button
                                                                variant="link"
                                                                className="p-0"
                                                                onClick={() => {
                                                                  setMappingObjectValue(
                                                                    getValues(
                                                                      `valueGroups[${i}].cdValues[${index}].childValues.${childIndex}`,
                                                                    ),
                                                                  );
                                                                  setMappingObjectStringValue(
                                                                    `valueGroups[${i}].cdValues[${index}].childValues.${childIndex}.comment`,
                                                                  );
                                                                  setShowCommentModal(true);
                                                                }}
                                                              >
                                                                <ContentPasteOutlined />
                                                              </Button>
                                                            </Stack>
                                                          </Col>
                                                        </Row>
                                                      );
                                                    })}
                                              </>
                                            )}
                                          </>
                                        );
                                      })}
                                </>
                              </>
                            );
                          })}
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end mb-3">
                  <Stack gap={3} direction="horizontal">
                    <Button
                      variant="tertiary"
                      onClick={() => {
                        reset();
                        setDirty(false);
                      }}
                    >
                      Nullstill alle tilstandsgrader <RemoveCircleOutline />
                    </Button>
                    <Button
                      variant="tertiary"
                      onClick={handleSubmit((d) => addMappingObjectState(d, MappingState.InProgress))}
                      disabled={!dirty}
                    >
                      Lagre utkast <SaveOutlined />
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={handleSubmit((d) => addMappingObjectState(d, MappingState.Closed))}
                    >
                      Fullføre kartlegging av objekt <AssignmentTurnedInOutlined />
                    </Button>
                  </Stack>
                </Col>
              </Row>
              <MappingObjectPDModal show={showPDModal} onHide={() => setShowPDModal(false)} data={descriptionData} />
              <MappingObjectValueCommentModal
                show={showCommentModal}
                onHide={() => setShowCommentModal(false)}
                data={mappingObjectValue}
                register={register}
                value={mappingObjectStringValue}
                objectToMapId={data.objectToMapId}
              />
              <MappingObjectMainCommentModal
                show={showMainCommentModal}
                onHide={() => setShowMainCommentModal(false)}
                comment={data.comment}
                objectName={data.objectName}
                register={register}
                reset={reset}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};
